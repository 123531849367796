/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Button, Grid, Select, MenuItem, FormControl, Box, InputAdornment, TextField } from '@mui/material';
import { FilterIcon, SearchIcon } from 'components/Icons';
import { ModalPromoteAdmins } from './ModalPromoteAdmins';

const actions = [{ value: 'openModal', label: 'Add/Remove Admin Privs' }];

export const AdminFilters = ({ selectedRows, org, fetchAdminsFromOrg, admins }) => {
  const [selectedRole, setSelectedRole] = useState('all');
  const [filters, setFilters] = useState({
    search: '',
    activated: false,
  });
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [adminOverride, setAdminOverride] = useState([]);

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedRole(value);

    switch (value) {
      case 'openModal':
        if (selectedRows.length > 0) {
          setAdminOverride(admins.filter((admin) => selectedRows.includes(admin.id)));
          setShowAdminModal(true);
        }
        break;
      default:
        break;
    }
  };

  const filterOptions = (search) => {
    setFilters((prev) => ({
      ...prev,
      search,
    }));
  };

  const handleOpenFilters = () => {};

  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              displayEmpty
              disabled={selectedRows.length === 0}
              sx={{
                height: '44px',
                width: '320px',
                backgroundColor: 'transparent',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '& .MuiSelect-select': {
                  color: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
              }}
            >
              <MenuItem value="all" disabled>
                Select Option
              </MenuItem>
              {actions.map((action, index) => (
                <MenuItem
                  key={action.value}
                  value={action.value}
                  style={{
                    fontSize: '16px',
                    padding: '10px 14px',
                    borderTop: index === actions.length - 1 ? '1px solid gray' : 'none',
                  }}
                >
                  {action.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} sx={{ position: 'absolute', right: '16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'end' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size={20} />
                  </InputAdornment>
                ),
              }}
              value={filters?.search || ''}
              onChange={(e) => filterOptions(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  backgroundColor: 'transparent',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '& input': {
                    color: 'white',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleOpenFilters}
              sx={{
                height: '40px',
                minWidth: '100px',
                whiteSpace: 'nowrap',
                backgroundColor: filters?.activated ? 'primary.main' : 'transparent',
                '&:hover': {
                  backgroundColor: filters?.activated ? 'primary.dark' : 'rgba(255, 255, 255, 0.08)',
                },
              }}
            >
              <FilterIcon size={15} /> <div style={{ marginLeft: '6px' }}>Filters {filters?.activated && '(On)'}</div>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ModalPromoteAdmins
        isOpen={showAdminModal}
        onClose={() => {
          setShowAdminModal(false);
          setSelectedRole('all');
        }}
        title="Promote Admins"
        description="Promote users to admins"
        memberRoles={[]}
        onMemberRoleChange={() => {}}
        onConfirm={fetchAdminsFromOrg}
        rolesConfig={[
          { key: 'participantCoordinator', label: 'Role' },
          { key: 'contentCoordinator', label: 'Role' },
          { key: 'projectAdmin', label: 'Role' },
        ]}
        orgId={org.id}
        adminOverride={adminOverride}
      />
    </Box>
  );
};
