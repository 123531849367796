/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from '@mui/material';
import { ShieldIcon } from 'components/Icons';
import OrganizationService from 'services/OrganizationService';

export const ModalPromoteAdmins = ({
  isOpen,
  onClose,
  title,
  description,
  memberRoles,
  onMemberRoleChange,
  onConfirm,
  rolesConfig,
  orgId,
  adminOverride = [],
}) => {
  const [admins, setAdmins] = useState([]);
  useEffect(() => {
    const fetchAdmins = async () => {
      const {
        data: { data },
      } = await OrganizationService.getOrganizationMembers(orgId);
      setAdmins(data.users);
    };
    if (adminOverride.length > 0) {
      setAdmins(adminOverride);
    } else {
      fetchAdmins();
    }
  }, [adminOverride, orgId]);
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        variant="h4"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
          pb: 0,
        }}
      >
        <ShieldIcon size={40} stroke="#00e676" background="#b9f6ca" />
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'normal' }}>
            {description}
          </Typography>

          <Table sx={{ mt: 1, backgroundColor: 'transparent' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '30%',
                    fontWeight: 'normal',
                    border: 0,
                  }}
                />
                {rolesConfig.map((role) => (
                  <TableCell
                    key={role.key}
                    align="center"
                    sx={{
                      fontSize: '12px',
                      color: '#fff',
                      fontWeight: 'normal',
                      border: 0,
                    }}
                  >
                    {role.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map(({ firstName, name, id }) => (
                <TableRow
                  key={id}
                  sx={{
                    border: 0,
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {firstName || name}
                  </TableCell>
                  {rolesConfig.map((role) => (
                    <TableCell
                      key={role.key}
                      align="center"
                      sx={{
                        border: 0,
                      }}
                    >
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'rgba(255, 255, 255, 0.7)',
                          '&.Mui-checked': {
                            color: '#FF9100',
                          },
                        }}
                        checked={memberRoles[id]?.[role.key] || false}
                        onChange={() => onMemberRoleChange(id, role.key)}
                        disabled={role.disabled}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          gap: '1em',
          padding: '2em',
        }}
      >
        <Button sx={{ width: '50%' }} onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: '#FF9100',
            color: '#000',
            '&:hover': {
              backgroundColor: '#cc7400',
            },
            width: '50%',
          }}
          onClick={onConfirm}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalPromoteAdmins.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
