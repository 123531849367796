import React, { useContext } from 'react';
import { Button, Tooltip } from '@mui/material';
import { ArrowTopRight, CirclePlusIcon } from 'components/Icons';
import { Card as CardComponent } from 'components/Card';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { useStyles } from 'pages/Admin/Billing/Credits/styles';
import { Map } from './Map';
import { PortalsHeader } from './PortalsHeader';
import { useStyles as useLocalStyles } from './styles';

export const PortalsItemsDetail = () => {
  const { config } = useContext(SidebarContext);
  const component = config.header.find((item) => item.name === 'portalsItemsDetail');
  const { actions } = component;
  const { portal, map } = actions;
  const classes = useStyles();
  const localClasses = useLocalStyles();

  return (
    <div className={classes.container}>
      {/* header */}
      <PortalsHeader onClose={() => actions.handleClose()} title={map?.name} subTitle={portal?.title} />
      {/* content */}
      <CardComponent noPadding>
        <div className={localClasses.cardComponent}>
          <div className={classes.generalContent}>
            {/* map component */}
            <div className={localClasses.mapDetail}>
              <div className={localClasses.mapDetailHeader}>
                <div className={classes.titleSecond}>{portal.title}</div>
                <div className={classes.titleSubHeading}>Views: {portal.numViews}</div>
              </div>

              {/* map */}
              <Map map={portal} />

              {/* display button */}
              <div className={localClasses.mapDetailActions}>
                <Tooltip title="Add to Map/GIS">
                  <Button
                    onClick={() => actions.addPortalToGis(portal, -1)}
                    className={localClasses.portalItemsButton}
                    style={{ float: 'right', marginLeft: '8px' }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    disableElevation
                  >
                    <CirclePlusIcon size={20} fill="#000000" />
                  </Button>
                </Tooltip>
                <Tooltip title="View Details">
                  <Button
                    onClick={() => window.open(`https://arcgis.com/home/item.html?id=${portal.id}`, '_blank')}
                    className={localClasses.portalItemsActions}
                    color="primary"
                    variant="contained"
                    disableElevation
                  >
                    <ArrowTopRight size={14} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </CardComponent>
    </div>
  );
};
