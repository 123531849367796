import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useGlobalStyles } from 'styles';
import { SidebarPanel } from 'components/SidebarPanel';
import mapImge from 'images/projectMap.png';
import externalMapImge from 'images/externalProjectMap.png';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/TabPanel';
import { getInitials, getNameRole } from 'lib/generalFunctions';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import { Avatar } from 'components/Avatar';
import { InfoPanelBodyHeader } from 'components/InfoPanelBodyHeader/InfoPanelBodyHeader';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { getProject } from 'slices/allProjectReducer';
import { useDispatch } from 'react-redux';
import { ProjectDetail } from 'components/ProjectDetail/ProjectDetail';

// eslint-disable-next-line no-unused-vars
export const InfoPanelBody = ({ loading, detail, projUsers, projTeams, navigateToPermissions }) => {
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const [value, setValue] = useState(0);
  const { setOpen, handleClose, config } = useContext(SidebarContext);
  const { setActivePanel, setFullWidth, setHeaderPanels } = useContext(SidebarDetailContext);
  const component = config?.header.find((item) => item.name === 'info');

  const projectDetail = () => {
    setOpen(false);
    handleClose();
    dispatch(getProject(detail?.id));
    setHeaderPanels([
      {
        name: 'projectDetail',
        component: ProjectDetail,
        destination: component?.destination,
      },
    ]);
    setFullWidth(true);
    setActivePanel('projectDetail');
  };

  return (
    <SidebarPanel title="Project Details" loading={loading}>
      <InfoPanelBodyHeader
        image={config?.search?.external ? externalMapImge : mapImge}
        name={getInitials(detail?.name)}
      />
      <div className={globalClasses.infoPanelBody}>
        <Typography mb={1} variant="h1" className={globalClasses.infoPanelName}>
          {detail?.name}
        </Typography>
        <Typography mb={0.5} variant="h5">
          {moment(detail?.createdAt).format('MM/DD/YY')}
        </Typography>
        <Typography mb={0.5}>{projUsers?.length} Participants</Typography>
        <Typography mb={0.5}>{detail?.observationsCount} Observations</Typography>
        <UuidButton data={detail} />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => projectDetail()}
          size="medium"
          sx={{ marginTop: '2rem' }}
          className={globalClasses.openDetail}
        >
          Open
        </Button>
        <Typography mt={1} className={globalClasses.infoPanelAboutText}>
          About
        </Typography>
        <Typography mb={1} variant="body2" className={globalClasses.infoPanelDescription}>
          {detail?.description}
        </Typography>
        <Tabs value={value} className={globalClasses.infoPanelTabs} sx={{ width: '100%' }}>
          <Tab label="Teams" onClick={() => setValue(0)} sx={{ width: '50%' }} />
          <Tab label="Participants" onClick={() => setValue(1)} sx={{ width: '50%' }} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={globalClasses.infoTabPanel}>
            {projTeams?.map((team) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar key={team?.id} initials={getInitials(team?.name)} size={48} />
                <div>
                  <Typography>{team.name}</Typography>
                  <Typography className={globalClasses.greyTypography}>{getNameRole([...team.roles])}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={globalClasses.infoTabPanel}>
            {projUsers?.map((user) => (
              <div className={globalClasses.nameContainer} style={{ margin: '10px' }}>
                <Avatar
                  key={user?.id}
                  initials={resolveUserInitials({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  })}
                  size={48}
                />
                <div>
                  <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                  <Typography className={globalClasses.greyTypography}>{getNameRole([...user.roles])}</Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
      </div>
    </SidebarPanel>
  );
};

InfoPanelBody.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  projUsers: PropTypes.array,
  projTeams: PropTypes.array,
  navigateToPermissions: PropTypes.func,
};

InfoPanelBody.defaultProps = {
  loading: false,
  detail: { id: null, name: '' },
  projUsers: [],
  projTeams: [],
  navigateToPermissions: () => {},
};
