import React, { useEffect, useState, useMemo } from 'react';
import { Grid, ButtonGroup, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AddUuid } from 'components/AddUuid/AddUuid';
import { NewAdminHeaderTitle } from 'components/AdminHeaderTitle/NewAdminHeaderTitle';
import { Card } from 'components/Card/Card';
import { CirclePlusIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { usePagination, useSortBy, useTable } from 'react-table';
import {
  getMapGisServicesAll,
  setPageFilter,
  setFilters,
  setSearch,
  preloadFilterButtons,
  resetAdminMapGis,
} from 'slices/mapGisReducer';
import { externalActionType, externalDataType } from 'lib/external-data';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { mapGisOptions } from 'lib/topBarOptions';
import { ModalLink } from 'smartComponents/ModalLinkToMapOrServices';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { MapCard } from 'components/MapGis/MapCard/MapCard';
import { ActiveMapGis } from 'components/MapGis/ActiveMapGis/ActiveMapGis';
import { ModalDeleteMapGis } from 'components/MapGis/ModalDeleteMapGis/ModalDeleteMapGis';
import { MapGisActions } from 'components/MapGis/MapGisActions/MapGisActions';
import { If } from 'components/If';
import { v4 } from 'uuid';
import { PortalsFormModal } from '../MapGisPortals/PortalsFormModal';
import { MapViewComponent } from './MapViewComponent';
import { SearchPanel } from './SearchPanel';
import { useStyles } from './styles';
import { mapGisAction, mapGisFilterButtons, mapGisFilterButtonsValues } from './helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import SplashImage from 'images/ContentSplash.png';

const tableColumns = () => [
  {
    Header: 'Off/On',
    accessor: (rowData) => {
      const { isActive } = rowData;
      return (
        <div>
          <span>
            <ActiveMapGis on={isActive} webmap={rowData} />
          </span>
        </div>
      );
    },
  },
  {
    Header: 'Map/GIS Layer',
    accessor: (rowData) => rowData.mapName,
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { mapDescription } = rowData;
      return mapDescription || 'No Description Found.';
    },
  },
  {
    Header: 'Set-At-Level',
    accessor: (rowData) => {
      const { levelDesc } = rowData;
      return mapGisFilterButtonsValues[levelDesc];
    },
  },
  {
    Header: 'Date',
    accessor: (rowData) => moment(rowData.createdAt).format('MM/DD/YY'),
  },
];

let debounceTimeout = null;

export const MapGis = ({ external }) => {
  const [openMapServices, setOpenMapServices] = useState(false);
  const [openUuid, setOpenUuid] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [action, setAction] = useState();
  const [tableToggle, setTableToggle] = useState(true);
  const [openPortalModal, setOpenPortalModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState('');
  const [openDeletion, setOpenDeletion] = useState(false);
  const { loading, data, filters, totalCount } = useSelector((state) => state.adminMapGis);
  const { items: buttons } = useSelector((state) => state.adminMapGis.panels.buttons);
  const columns = useMemo(() => tableColumns(), []);
  const localClasses = useStyles();
  const dispatch = useDispatch();

  // Adding the filter component
  useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
      actions: [],
    },
  });

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: filters.page_size,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const loadData = () => {
    const sendFilters = { ...filters };
    dispatch(getMapGisServicesAll({ filters: sendFilters, exclude: mapGisFilterButtons.PROJ }));
  };

  // UUID functions
  const handleCloseUuid = (flag) => {
    setOpenUuid(false);
    if (flag) loadData(filters);
  };

  const handleRowClick = ({ original }) => {
    setMapData(original);
  };

  const handleToggle = (toggle) => {
    setTableToggle(toggle);
  };

  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const handleAction = (actionValue, row) => {
    setAction({ action: actionValue, data: row });
    if (actionValue === mapGisAction.DELETE) {
      setOpenDeletion(true);
    }
  };

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      dispatch(getMapGisServicesAll({ filters, exclude: mapGisFilterButtons.PROJ }));
      dispatch(preloadFilterButtons({ exclude: mapGisFilterButtons.PROJ }));
    }, 300);
  }, [filters]);

  // set the first row
  useEffect(() => {
    if (data.length) {
      setMapData(data[0]);
    }
  }, [data]);

  // Set the initial map data
  useEffect(() => {
    dispatch(resetAdminMapGis());
  }, []);

  const EmptyMapGisContainer = () => (
    <WidgetSplash
      alt="MapGis Splash"
      title="There are currently no Maps/GIS Services at the account level. Click the create button to begin adding Maps/GIS Services."
      image={SplashImage}
      titleAboveImage={false}
    />
  );

  const MapGisContainer = () => (
    <Card noPadding>
      <TopBar
        handleToggle={handleToggle}
        handleSearch={handleSearch}
        options={mapGisOptions}
        searchValue={filters?.search}
        activatedFilter={filters.activated}
        buttonGroup={
          <ButtonGroup style={{ marginLeft: '5px', height: '40px' }}>
            {buttons.map((button) => (
              <Button
                onClick={() => {
                  setSelectedButton(button.value);
                  dispatch(setFilters({ level: button.value }));
                }}
                key={`${v4()}${new Date().getMilliseconds()}`}
                color={selectedButton === button.value ? 'secondary' : 'primary'}
                variant="contained"
                disableElevation
              >
                {button.text}
              </Button>
            ))}
          </ButtonGroup>
        }
        tableCardToggle
        arcgis
      />
      <If cond={tableToggle}>
        <MapViewComponent mapData={mapData} />
        <GeneralTable
          table={table}
          selectedRow={mapData?.id}
          handleRowClick={handleRowClick}
          rowComponent={MapGisActions}
          actionFunction={(event, row) => handleAction(event, row)}
          external={external}
          arcgis
        />
        <TablePagination table={table} />
      </If>
      <If cond={!tableToggle}>
        <Grid container spacing={1} className={localClasses.cardsContainer}>
          {data?.map((item) => (
            <Grid key={item.id} item lg={4} md={4} sm={12} xs={12}>
              <MapCard data={item} handleAction={handleAction} />
            </Grid>
          ))}
        </Grid>
      </If>
    </Card>
  );

  if (loading && !data.length) {
    return (
      <AccountSettingsContainer
        title={<NewAdminHeaderTitle section="Linked Maps/GIS Services: Account Level" />}
        actions={[
          {
            icon: CirclePlusIcon,
            color: 'primary',
            text: 'Add Portal',
            disableElevation: true,
            onClick: () => {
              setOpenPortalModal(true);
            },
          },
          {
            icon: CirclePlusIcon,
            text: 'Map/GIS Service',
            fill: '#000000',
            disableElevation: true,
            onClick: () => {
              setOpenMapServices(true);
              setAction({});
            },
          },
        ]}
        showSidebar
        newStyle
      >
        <Loader loading={loading} height={250} />
        {openUuid?.type === externalActionType.ADD && (
          <AddUuid
            handleClose={handleCloseUuid}
            type={externalDataType.PROJ}
            variant={openUuid.variant}
            item={openUuid}
          />
        )}
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<NewAdminHeaderTitle section="Linked Maps/GIS Services: Account Level" />}
      actions={[
        {
          icon: CirclePlusIcon,
          color: 'primary',
          text: 'Add Portal',
          disableElevation: true,
          onClick: () => {
            setOpenPortalModal(true);
          },
        },
        {
          icon: CirclePlusIcon,
          text: 'Map/GIS Service',
          fill: '#000000',
          disableElevation: true,
          onClick: () => {
            setOpenMapServices(true);
            setAction({});
          },
        },
      ]}
      showSidebar
      newStyle
    >
      {data.length > 0 ? MapGisContainer() : <EmptyMapGisContainer />}
      {openUuid?.type === externalActionType.ADD && (
        <AddUuid
          handleClose={handleCloseUuid}
          type={externalDataType.PROJ}
          variant={openUuid.variant}
          item={openUuid}
        />
      )}
      <ModalLink
        handleOpen={(value) => {
          setOpenMapServices(value);
          setAction({});
        }}
        open={openMapServices || action?.action === mapGisAction.EDIT}
        onClose={() => loadData()}
        data={action}
      />
      <ModalDeleteMapGis
        handleOpen={(value, refresh) => {
          if (refresh) {
            loadData();
          }
          setOpenDeletion(value);
        }}
        open={openDeletion}
        data={action?.data}
      />
      {/* modal with the portal form */}
      <PortalsFormModal onClose={() => setOpenPortalModal(false)} open={openPortalModal} isOnline />
    </AccountSettingsContainer>
  );
};

MapGis.propTypes = {
  external: PropTypes.bool,
};

MapGis.defaultProps = {
  external: false,
};
