import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dialog, DialogTitle, DialogContent, Grid, Button, Typography, InputLabel } from '@mui/material';
import { FeatureSet2Icon, CloseIcon } from 'components/Icons';
import { FormInput } from 'components/FormInput';
import { Loader } from 'components/Loader';
import { If } from 'components/If';
import { Form } from 'lib/form';
import { useStyles } from './styles';
import Service from 'services/MapGisService';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

export const PortalsFormModal = ({ onClose, open, isOnline, portalData }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const regex = new RegExp(
    '^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)*[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\/[a-zA-Z0-9_\\-\\/\\.]*)?(\\?[^#\\s]*)?$',
    'g'
  );

  /* */
  const save = (form) => {
    setLoading(true);
    const { name, url } = form;

    // validate if the form is to save or update
    if (portalData?.action && portalData?.action === 'edit') {
      Service.updatePortal(portalData?.row?.id, { name, url })
        .then(() => {
          onClose({ load: true });
          setLoading(false);
        })
        .catch(() => setLoading(true));
    } else {
      Service.createPortal({ name, url, isOnline })
        .then(() => {
          onClose({ load: true });
          setLoading(false);
        })
        .catch(() => setLoading(true));
    }
  };
  /* */

  /* initial formik form */
  const formik = useFormik({
    initialValues: {
      name: '',
      url: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(5, 'Name must be at least 5 characters.')
        .max(50, 'Name must be at most 50 characters.')
        .required('Name is required.'),
      url: Yup.string().matches(regex, 'Enter correct url.').required('URL is required.'),
    }),
    enableReinitialize: true,
    onSubmit: (form) => save(form),
  });

  /* */
  const closeForm = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (portalData?.action && portalData?.action === 'edit') {
      formik.setFieldValue('name', portalData?.row?.name);
      formik.setFieldValue('url', portalData?.row?.url);
    }
  }, [portalData]);
  /* */

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FeatureSet2Icon size={50} />
          <Button
            onClick={closeForm}
            className={classes.headerButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            <CloseIcon size={20} />
          </Button>
        </div>
        <Typography className={classes.title} component="div">
          {isOnline ? 'Connect to ArcGIS Online' : 'Connect to Portal'}
        </Typography>
        <Typography className={classes.subTitle} variant="subtitle2" component="div">
          {isOnline
            ? 'Add URL to connect to your ArcGIS Online.\nAuthentication must be enabled for proper functionality.'
            : 'Add URL to connect to your portal.\nSSO must be enabled for proper functionality.'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <If cond={loading}>
            {/* loading */}
            <div className={classes.loader}>
              <Loader loading={loading} height={50} size={32} />
            </div>
          </If>
          <If cond={!loading}>
            {/* name */}
            <Grid xs={12} sm={12} lg={12} item>
              <InputLabel className={classes.inputLabel}>Name</InputLabel>
              <FormInput
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.name}
                {...Form.fieldErrorHelper(formik, 'name')}
                id="name"
                name="name"
                required
                fullWidth
              />
            </Grid>

            {/* url */}
            <Grid xs={12} sm={12} lg={12} item>
              <InputLabel className={classes.inputLabel}>URL</InputLabel>
              <FormInput
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.url}
                rows={3}
                {...Form.fieldErrorHelper(formik, 'url')}
                id="url"
                name="url"
                required
                fullWidth
                multiline
              />
            </Grid>
          </If>
        </Grid>

        <div className={classes.actions}>
          <div style={{ marginRight: 5 }} className={classes.actionsButton}>
            <Button
              onClick={closeForm}
              disabled={loading}
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
            >
              Cancel
            </Button>
          </div>

          <div style={{ marginLeft: 5 }} className={classes.actionsButton}>
            <Button
              onClick={() => formik.handleSubmit()}
              disabled={loading}
              color="secondary"
              variant="contained"
              fullWidth
              disableElevation
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

PortalsFormModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isOnline: PropTypes.bool.isRequired,
  portalData: PropTypes.object,
};

PortalsFormModal.defaultProps = {
  onClose: () => {},
  open: false,
  portalData: {},
};
