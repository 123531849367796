import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const ShieldIcon = ({ size, stroke = '#fff', fill = 'none', background = '' }) => (
  <>
    {background ? (
      <div
        style={{
          width: size * 1.6,
          height: size * 1.6,
          borderRadius: '100%',
          background: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: size * 1.3,
            height: size * 1.3,
            borderRadius: '100%',
            background,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Svg viewBox="0 0 24 24" size={size}>
            <path
              id="Icon"
              d="M11.302 21.6147C11.5234 21.7439 11.6341 21.8085 11.7903 21.842C11.9116 21.868 12.0884 21.868 12.2097 21.842C12.3659 21.8085 12.4766 21.7439 12.698 21.6147C14.646 20.4783 20 16.9083 20 11.9999V7.21747C20 6.41796 20 6.0182 19.8692 5.67457C19.7537 5.37101 19.566 5.10015 19.3223 4.8854C19.0465 4.64231 18.6722 4.50195 17.9236 4.22122L12.5618 2.21054C12.3539 2.13258 12.25 2.0936 12.143 2.07815C12.0482 2.06444 11.9518 2.06444 11.857 2.07815C11.75 2.0936 11.6461 2.13258 11.4382 2.21054L6.0764 4.22122C5.3278 4.50195 4.9535 4.64231 4.67766 4.8854C4.43398 5.10015 4.24627 5.37101 4.13076 5.67457C4 6.0182 4 6.41796 4 7.21747V11.9999C4 16.9083 9.35396 20.4783 11.302 21.6147Z"
              stroke={stroke}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={fill}
            />
          </Svg>
        </div>
      </div>
    ) : (
      <Svg viewBox="0 0 24 24" size={size}>
        <path
          id="Icon"
          d="M11.302 21.6147C11.5234 21.7439 11.6341 21.8085 11.7903 21.842C11.9116 21.868 12.0884 21.868 12.2097 21.842C12.3659 21.8085 12.4766 21.7439 12.698 21.6147C14.646 20.4783 20 16.9083 20 11.9999V7.21747C20 6.41796 20 6.0182 19.8692 5.67457C19.7537 5.37101 19.566 5.10015 19.3223 4.8854C19.0465 4.64231 18.6722 4.50195 17.9236 4.22122L12.5618 2.21054C12.3539 2.13258 12.25 2.0936 12.143 2.07815C12.0482 2.06444 11.9518 2.06444 11.857 2.07815C11.75 2.0936 11.6461 2.13258 11.4382 2.21054L6.0764 4.22122C5.3278 4.50195 4.9535 4.64231 4.67766 4.8854C4.43398 5.10015 4.24627 5.37101 4.13076 5.67457C4 6.0182 4 6.41796 4 7.21747V11.9999C4 16.9083 9.35396 20.4783 11.302 21.6147Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={fill}
        />
      </Svg>
    )}
  </>
);
ShieldIcon.propTypes = {
  size: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  background: PropTypes.string,
};

ShieldIcon.defaultProps = {
  size: 24,
  stroke: '#fff',
  fill: 'none',
  background: '',
};
