/* eslint-disable no-shadow */
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import PropTypes from 'prop-types';
// import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Avatar } from 'components/Avatar';
import { Card } from 'components/Card/Card';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems';
import { DialogMessage, dialogMessageAction } from 'components/DialogMessage';
import { CirclePlusIcon, PlusIcon } from 'components/Icons';
// import { AddMapServiceIcon } from 'components/Icons/AddMapServiceIcon';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import SplashImage from 'images/ContentSplash.png';
import { Roles } from 'lib/roles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import {
  deleteOrganization,
  getOrganizations,
  getSingleOrg,
  setPageFilter,
  updateOrgInfo,
  setSearch,
  setOrganization,
  setActiveTab,
} from 'slices/organizationReducer';
import { ModalEditOrganization } from 'smartComponents/ModalEditOrganization';
import { ModalOrganizationInviteUsers } from 'smartComponents/ModalOrganizationInviteUsers';
import { ModalOrganizationNew } from 'smartComponents/ModalOrganizationNew';
// import { ModalLinkToMapOrServices } from 'smartComponents/ModalLinkToMapOrServices';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { orgOptions } from 'lib/topBarOptions';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { useGlobalStyles } from 'styles';
import { InfoPanel } from './InfoPanel';
import { SearchPanel } from './SearchPanel';
import { Typography } from '@mui/material';
import { MoreThanIcon } from 'components/Icons/MoreThanIcon';
import { getInitials } from 'lib/generalFunctions';
import { OrgDashboard } from './OrgDashboard/OrgDashboard';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSidebarDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { ModalTransferMembers } from './OrgDashboard/Members/ModalTransferMembers';
import OrganizationService from 'services/OrganizationService';

const tableColumns = (classes) => [
  {
    Header: 'Name',
    accessor: (rowData) => (
      <div className={classes.avatarRow} style={{ alignItems: 'baseline' }}>
        <span style={{ paddingRight: '5px' }}>
          <Avatar initials={getInitials(rowData.name)} size={40} key={rowData.id} />
        </span>
        {rowData.name}
      </div>
    ),
  },
  {
    Header: 'Admins',
    accessor: (rowData) => {
      const { admins } = rowData;
      return (
        <div className={classes.avatarRow}>
          {admins.slice(0, 2).map((admin) => (
            <Avatar initials={getInitials(`${admin.firstName} ${admin.lastName}`)} size={30} key={admin.id} />
          ))}
          {admins.length > 2 ? <Avatar initials={`+${admins.length - 2}`} size={30} /> : null}
        </div>
      );
    },
  },
  {
    Header: 'Members',
    accessor: (rowData) => {
      const { userCount, id } = rowData;
      return <div key={id}>{userCount}</div>;
    },
  },
  {
    Header: 'Projects',
    accessor: (rowData) => {
      const { projects } = rowData;
      return (
        <div className={classes.avatarRow}>
          {projects.slice(0, 4).map((project) => (
            <Avatar initials={getInitials(`${project.name}`)} size={30} key={project.id} />
          ))}
          {projects.length > 4 ? <Avatar initials={`+${projects.length - 4}`} size={30} /> : null}
        </div>
      );
    },
  },
];

let debounceTimeout = null;
export const Organizations = () => {
  const classes = useGlobalStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();
  const { loading, data, filters, orgsCount } = useSelector((state) => state.organizations);
  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInviteUsers, setOpenInviteUsers] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [currentOrg, setCurrentOrg] = useState(null);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [transferMembersList, setTransferMembersList] = useState([]);
  const history = useHistory();
  const actions = [
    {
      key: 'add_new',
      icon: CirclePlusIcon,
      text: 'Add New',
      onClick: () => {
        setOpen(true);
      },
    },
  ];
  // const [isOpenedDashboard, setIsOpenDashboard] = useState(false);
  const columns = React.useMemo(() => tableColumns(classes), []);
  const search = filters.search || '';

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(orgsCount / filters.page_size),
      totalCount: orgsCount,
      pageLength: data.length,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const fetchOrgList = async () => {
    try {
      const {
        data: { data },
      } = await OrganizationService.getOrganizations();

      setOrgList(data);
    } catch (error) {
      console.error('Error fetching orgs:', error);
      setOrgList([]);
    }
  };

  useEffect(() => {
    dispatch(setSearch());
    fetchOrgList();
  }, []);

  const {
    state: { pageIndex, pageSize },
  } = table;

  const loadData = () => {
    const sendFilters = {
      ...filters,
      allOrgByProjectId: true,
      sort_by: Object.values(filters.sort_by),
    };
    dispatch(getOrganizations(sendFilters));
  };

  useEffect(() => {
    debounceTimeout = setTimeout(() => {
      loadData();
    }, 150);
    return () => clearTimeout(debounceTimeout);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  // Manage the Open of Edit a user Modal
  const handleEditOrg = async (orgData) => {
    dispatch(
      updateOrgInfo({
        orgId,
        name: orgData.name,
        description: orgData.description,
      })
    );
    setOpenEditOrg(false);
  };

  const handleOpenEditOrg = async (orgData) => {
    setOpenEditOrg(true);
    setOrgId(orgData.original.id);
    setCurrentOrg(orgData.original);
  };

  // Manage the Open & Close Invite users to an Organization Modal
  /* const handleOpenInviteUsers = async (id) => {
    dispatch(getSingleOrg({ orgId: id, orgRole: Roles.ORG_ADMIN }));
    setOpenInviteUsers(true);
    setOrgId(id);
  }; */

  const handleInviteUsers = async () => {
    setOpenInviteUsers(false);
  };

  const handleCancelInviteUsers = () => {
    setOpenInviteUsers(false);
  };

  const handleCancelEditUser = () => {
    setOpenEditOrg(false);
  };

  // Manage the Open of Add a New Organization
  const organizationModalToggle = (bool) => {
    setOpen(bool);
  };

  // Manage the Open of a New modal to link to Map or Services
  // const mapServicesModalToggle = (bool) => {
  //   setOpenMapServices(bool);
  // };

  // Manage the Remove of an Organization
  const handleOpenRemoveOrg = async (org) => {
    setOrgId(org.id);
    setCurrentOrg(org);
    setOpenDelete(true);
  };

  const handleDeleteOrg = async () => {
    await dispatch(deleteOrganization({ orgId }));
    loadData();
    setOpenDelete(false);
  };

  const handleCancelOrg = () => {
    setOpenDelete(false);
  };

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
      header: [],
      actions: [
        {
          icon: PlusIcon,
          onClick: () => {
            organizationModalToggle(true);
          },
        },
      ],
    },
  });

  const handleRowClick = async (rowData) => {
    // dispatch redux action to load user data
    // set sidebar active panel to user info and open if necessary
    setSelectedRow(rowData.id);
    setCurrentOrg(rowData.original);
    dispatch(getSingleOrg({ orgId: rowData.original.id, orgRole: Roles.ORG_ADMIN }));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
      },
    ]);
    displayPanel('info');
  };

  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const EmptyOrganizationsContainer = () => (
    <>
      <WidgetSplash
        alt="Organizations Splash"
        title="Does your company have organizations or divisions within it?Fine-tune your control by adding them now."
        image={SplashImage}
        cta="Add an Organization"
        onClick={() => organizationModalToggle(true)}
      />
      {open && <ModalOrganizationNew isOpen={open} handleOpen={organizationModalToggle} />}
    </>
  );

  const {
    displayPanel: displayPanelDetail,
    setHeaderPanels: setHeaderPanelsDetail,
    setFullWidth: setFullWidthDetail,
  } = useSidebarDetail({
    open: false,
    config: {
      onClose: () => {},
    },
  });

  const onClickDashboard = (org) => {
    dispatch(setOrganization(org));
    // Set the active tab in Redux
    dispatch(setActiveTab('dashboard'));
    // Show the organization dashboard
    setHeaderPanelsDetail([
      {
        name: 'dashboard',
        component: OrgDashboard,
        actions: {
          handleClose: () => {
            displayPanelDetail('');
          },
        },
      },
    ]);
    setFullWidthDetail(true);
    displayPanelDetail('dashboard');
  };

  const handleNavigateToMembers = (org) => {
    // First, store the organization in Redux
    dispatch(setOrganization(org));
    // Set the active tab in Redux
    dispatch(setActiveTab('members'));
    // Show the organization dashboard with members tab active
    setHeaderPanelsDetail([
      {
        name: 'dashboard',
        component: OrgDashboard,
        actions: {
          handleClose: () => {
            displayPanelDetail('');
          },
        },
      },
    ]);
    setFullWidthDetail(true);
    displayPanelDetail('dashboard');
  };

  const handleNavigateToAdmins = (org) => {
    // First, store the organization in Redux
    dispatch(setOrganization(org));
    // Set the active tab in Redux
    dispatch(setActiveTab('admins'));
    // Show the organization dashboard with admins tab active
    setHeaderPanelsDetail([
      {
        name: 'dashboard',
        component: OrgDashboard,
        actions: {
          handleClose: () => {
            displayPanelDetail('');
          },
        },
      },
    ]);
    setFullWidthDetail(true);
    displayPanelDetail('dashboard');
  };

  const handleCopyUuid = (uuid) => {
    setSelectedUuid(uuid);
    setShowCopyModal(true);
  };

  const handleCloseCopyModal = () => {
    setShowCopyModal(false);
    setSelectedUuid(null);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
    setSelectedUuid(null);
  };

  const handleConfirmTransfer = async (targetOrg) => {
    /* eslint-disable no-shadow */
    const data = { targetOrganizationId: targetOrg, userIds: transferMembersList.map((user) => user.id) };
    try {
      await OrganizationService.transferMembers(selectedOrg, data);
      setShowTransferModal(false);
    } catch (error) {
      console.error('Error transferring members:', error);
    }
  };

  const handleOpenTransferModal = async (orgId) => {
    const {
      data: {
        data: { users },
      },
    } = await OrganizationService.getOrganizationMembers(orgId);
    setTransferMembersList(users);
    setSelectedOrg(orgId);
    setShowTransferModal(true);
  };

  const OrganizationActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => handleCopyUuid(row.original.id)}>Copy UUID</ContextMenuItem>

        <ContextMenuItem
          onClick={() => {
            onClickDashboard(row.original);
          }}
        >
          Organization Dashboard
        </ContextMenuItem>
        <ContextMenuItem onClick={() => handleNavigateToAdmins(row.original)}>Add/Edit Admins</ContextMenuItem>
        <ContextMenuItem onClick={() => handleNavigateToMembers(row.original)}>Add/Edit Members</ContextMenuItem>
        <ContextMenuItem onClick={() => handleOpenTransferModal(row.original.id)}>Transfer Members</ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            handleOpenEditOrg(row);
          }}
        >
          Edit Org Name/Description
        </ContextMenuItem>
        {/*   <ContextMenuItem onClick={() => handleOpenInviteUsers(row.original.id)}>Manage Users</ContextMenuItem> */}
        <WidgetDivider />
        <ContextMenuItem onClick={() => handleOpenRemoveOrg(row.original)}>Delete Organization</ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  OrganizationActions.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row: PropTypes.object.isRequired,
  };

  const OrganizationsContainer = () => (
    <>
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        handleRowClick={handleRowClick}
        rowComponent={OrganizationActions}
      />
      <TablePagination table={table} />
      <ModalOrganizationNew isOpen={open} handleOpen={organizationModalToggle} />
      {/* <ModalLinkToMapOrServices isOpen={openMapServices} handleOpen={mapServicesModalToggle} /> */}
      {openEditOrg && (
        <ModalEditOrganization
          isOpen={openEditOrg}
          onConfirm={handleEditOrg}
          onCancel={handleCancelEditUser}
          orgId={orgId}
          orgName={currentOrg.name}
        />
      )}
      {openInviteUsers && (
        <ModalOrganizationInviteUsers
          isOpen={openInviteUsers}
          onConfirm={handleInviteUsers}
          onCancel={handleCancelInviteUsers}
          orgId={orgId}
        />
      )}
      {openDelete && (
        <DialogMessage
          title={`Delete ${currentOrg.name}`}
          content={`Are you sure you want remove ${currentOrg.name} ?`}
          isOpen={openDelete}
          onConfirm={handleDeleteOrg}
          onCancel={handleCancelOrg}
          action={dialogMessageAction.delete}
        />
      )}
    </>
  );

  const title = (
    <div className={localClasses.title}>
      <button type="button" onClick={() => history.goBack()}>
        <Typography fontSize={18} lineHeight="28px" fontWeight="600">
          Administration
        </Typography>
      </button>
      <Typography color="#B1B1B1" fontSize={14} lineHeight="20px" fontWeight="500">
        <span style={{ margin: '0 10px', paddingRight: '3px' }}>
          <MoreThanIcon size={16} />
        </span>
        Organizations
      </Typography>
    </div>
  );

  return (
    <AccountSettingsContainer title={title} actions={actions} showSidebar>
      {loading && !data.length ? (
        <Loader loading={loading} height={250} />
      ) : (
        <Card noPadding>
          <TopBar
            options={orgOptions}
            searchValue={search}
            handleSearch={handleSearch}
            activatedFilter={filters.activated}
          />
          {data.length > 0 ? <OrganizationsContainer /> : <EmptyOrganizationsContainer />}
          {showCopyModal && <CopyUuidAlert id={selectedUuid} handleClose={handleCloseCopyModal} />}
          {showTransferModal && (
            <ModalTransferMembers
              isOpen={showTransferModal}
              onCancel={handleCloseTransferModal}
              onConfirm={handleConfirmTransfer}
              currentOrg={currentOrg}
              orgList={orgList}
            />
          )}
        </Card>
      )}
    </AccountSettingsContainer>
  );
};
