import React from 'react';
import { Button } from '@mui/material';
import { CloseIcon, MoreThanIcon } from 'components/Icons';
import { useStyles } from 'pages/Admin/Billing/Credits/styles';
import PropTypes from 'prop-types';

export const PortalsHeader = ({ onClose, title, subTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className="title">
        <div>
          <div className={classes.titleOne}>Administration</div>
          <span className={classes.titleBreadcumb}>
            <MoreThanIcon size={16} />
          </span>
          <div className={classes.titleSecond}>GIS Map/Services</div>
          <span className={classes.titleBreadcumb}>
            <MoreThanIcon size={16} />
          </span>
          <div className={classes.titleSecond}>Portals</div>
          <span className={classes.titleBreadcumb}>
            <MoreThanIcon size={16} />
          </span>
          <div className={classes.titleSubHeading}>{title}</div>
          {subTitle && (
            <>
              <span className={classes.titleBreadcumb}>
                <MoreThanIcon size={16} />
              </span>
              <div className={classes.titleSubHeading}>{subTitle}</div>
            </>
          )}
        </div>
        <Button onClick={onClose} className={classes.headerButton} variant="contained" color="primary" disableElevation>
          <CloseIcon size={20} />
        </Button>
      </div>
    </div>
  );
};

PortalsHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

PortalsHeader.defaultProps = {
  subTitle: '',
};
