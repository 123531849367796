import React, { useState } from 'react';
import { DialogContent, Button, Grid, InputLabel } from '@mui/material';
import { useFormik } from 'formik';
import { FormInput } from 'components/FormInput';
import { Loader } from 'components/Loader';
import { If } from 'components/If';
import { Form } from 'lib/form';
import { mapGisAction } from 'pages/Admin/MapGis/helpers';
import { useStyles } from './styles';
import Service from 'services/MapGisService';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

export const HostedFile = ({ handleClose, handleOpen, setPanel, data }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  /* */
  const save = (form) => {
    setLoading(true);
    const { name, description } = form;
    const body = {
      mapName: name,
      mapDescription: description,
    };

    // validate the action
    if (data?.action === mapGisAction.EDIT) {
      Service.updateMapGisService(data?.data?.id, body).then(() => {
        setLoading(false);
        handleOpen(false);
      });
    }
  };
  /* */

  /* initial formik form */
  const formik = useFormik({
    initialValues: {
      name: data?.data?.mapName || '',
      description: data?.data?.mapDescription || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(5, 'Name must be at least 5 characters')
        .max(50, 'Name must be at most 50 characters')
        .required('Name is required'),
      description: Yup.string()
        .min(12, 'Description must be at least 12 characters')
        .max(255, 'Description must be at most 255 chsaracters')
        .required('Description is required'),
    }),
    onSubmit: (form) => save(form),
  });

  return (
    <>
      <DialogContent>
        <Grid container>
          <If cond={loading}>
            <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
              <Loader loading={loading} height={50} size={32} />
            </div>
          </If>
          <If cond={!loading}>
            {/* name */}
            <Grid xs={12} sm={12} lg={12} xl={12} item>
              <InputLabel className={classes.inputLabel}>Name</InputLabel>
              <FormInput
                id="name"
                name="name"
                value={formik?.values?.name}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                {...Form.fieldErrorHelper(formik, 'name')}
                required
                fullwidth
              />
            </Grid>

            {/* description */}
            <Grid xs={12} sm={12} lg={12} xl={12} item>
              <InputLabel className={classes.inputLabel}>Description</InputLabel>
              <FormInput
                id="description"
                name="description"
                value={formik?.values?.description}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                {...Form.fieldErrorHelper(formik, 'description')}
                required
                fullwidth
              />
            </Grid>
          </If>
        </Grid>
      </DialogContent>

      <div className={classes.actions}>
        <div className={classes.actionsContent} style={{ marginRight: 5 }}>
          <Button
            onClick={() => {
              if (data?.action === mapGisAction.EDIT) {
                handleClose();
              } else {
                setPanel(null);
              }
            }}
            disabled={loading}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
          >
            {data?.action === mapGisAction.EDIT ? 'Cancel' : 'Back'}
          </Button>
        </div>

        <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
          <Button
            onClick={() => formik.handleSubmit()}
            disabled={loading}
            color="secondary"
            variant="contained"
            fullWidth
            disableElevation
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

HostedFile.propTypes = {
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  setPanel: PropTypes.func,
  data: PropTypes.object,
};

HostedFile.defaultProps = {
  handleClose: () => {},
  handleOpen: () => {},
  setPanel: () => {},
  data: {},
};
