import React from 'react';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenu } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';

export const MapGisPortalsActions = ({ row, handleConfirm }) => {
  // go to actions
  const handleAction = (action) => {
    handleConfirm(action, row?.original);
  };

  return (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => handleAction('edit')}>Edit Portal</ContextMenuItem>
        <ContextMenuItem onClick={() => handleAction('delete')}>Delete Portal</ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );
};
