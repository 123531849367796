import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const CirclePlusIcon = ({ size, fill }) => (
  <Svg viewBox="0 0 24 24" size={size} fill={fill}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      fill={fill}
    />
    <path d="M13 5h-2v6H5v2h6v6h2v-6h6v-2h-6V5z" fill={fill} />
  </Svg>
);

CirclePlusIcon.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

CirclePlusIcon.defaultProps = {
  size: 24,
  fill: '#FFFFFF',
};
