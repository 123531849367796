import React, { useContext, useState, useEffect } from 'react';
import PortalQueryParams from '@arcgis/core/portal/PortalQueryParams';
import { Button, Grid, Card, CardHeader, CardMedia, CardContent, Typography, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Card as CardComponent } from 'components/Card';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { Loader } from 'components/Loader';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { ModalWarning } from 'smartComponents/ModalWarning';
import { ModalSuccess } from 'smartComponents/ModalSuccess';
import { useStyles } from 'pages/Admin/Billing/Credits/styles';
import { PortalsItemsDetail } from './PortalsItemsDetail';
import { PortalsHeader } from './PortalsHeader';
import { useStyles as useLocalStyles } from './styles';
import Service from 'services/MapGisService';
import ContentSplash from 'images/ContentSplash.png';
import externalMapImage from 'images/externalProjectMap.png';
import moment from 'moment';

export const PortalsItems = () => {
  const [loading, setLoading] = useState(new Set());
  const [items, setItems] = useState([]);
  const [warning, setWarning] = useState({ open: false, content: '' });
  const [success, setSuccess] = useState({ open: false, content: '' });
  const { config } = useContext(SidebarDetailContext);
  const component = config.header.find((item) => item.name === 'portalsItems');
  const { actions } = component;
  const classes = useStyles();
  const localClasses = useLocalStyles();

  // methods to display the sidebar detail
  const { displayPanel, setHeaderPanels, setFullWidth } = useSidebar();

  /* */
  const addPortalToGis = (map, index) => {
    setLoading((prevState) => new Set([...prevState, index]));
    const action = actions.map;
    const type = String(map.type).toLocaleLowerCase();
    const payload = {
      arcgisMapId: map.id,
      isActive: true,
      levelId: action.id,
      levelDesc: 'ACCT',
      mapName: map.title,
      mapDescription: 'Hosted File - Portal',
      mapDesiredLabel: map.title,
      mapType: type,
      mapLayerOn: true,
      mapRootUrl: type === 'KML' || type === 'Feature Service' ? map.url : map.itemUrl,
      serviceDesc: 'Hosted File',
    };
    Service.saveMapGisService(payload).then((response) => {
      const portal = response?.data?.data;
      if (portal?.exist) {
        setWarning({
          open: true,
          content: `The item ${portal.mapName} alredy exist in Map/GIS.`,
        });
      } else {
        setSuccess({
          open: true,
          content: `The item ${portal.mapName} was added successfully.`,
        });
      }
      setLoading((prevState) => {
        const toUpdate = new Set(prevState);
        toUpdate.delete(index);
        return toUpdate;
      });
    });
  };

  const openMap = (map) => {
    setHeaderPanels([
      {
        name: 'portalsItemsDetail',
        component: PortalsItemsDetail,
        actions: {
          handleClose: () => {
            displayPanel('');
          },
          map: actions.map,
          portal: map,
          addPortalToGis,
        },
      },
    ]);
    setFullWidth(true);
    displayPanel('portalsItemsDetail');
  };

  const typeIn = (type) => !['Web Map', 'KML', 'Feature Service', 'Scene Service'].includes(type);

  const portalsItems = (page) => {
    const { username, orgId } = actions.portal.user;
    const params = new PortalQueryParams({
      query: `owner: ${username} OR orgid: ${orgId}`,
      sortField: 'modified',
      sortOrder: 'desc',
      start: page,
      num: 10,
    });
    // get the items from portal
    actions.portal.queryItems(params).then((response) => setItems(response));
  };

  useEffect(() => {
    portalsItems(1);
  }, [actions.portal]);
  /* */

  /* */
  const EmptyPortals = () => (
    <WidgetSplash
      image={ContentSplash}
      alt="Portals"
      title="There are currently no items in the portal"
      titleAboveImage
    />
  );

  const PortalsContainer = () => (
    <>
      {items?.results?.map((item, index) => (
        <Grid key={item.id} lg={4} md={4} sm={12} xs={12} item>
          <Card elevation={0}>
            {/* header */}
            <CardHeader title={item.title} subheader={moment(item.created).format('MMMM DD, YYYY')} />
            <CardMedia
              sx={{ height: 120 }}
              image={item.thumbnailUrl || externalMapImage}
              title={item.title}
              component="img"
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary" component="div">
                <div className={localClasses.portalItemsThumbnail}>
                  <img src={item.iconUrl} alt={item.displayName} />
                  <div style={{ marginLeft: 5 }}>{item.displayName}</div>
                </div>
              </Typography>
            </CardContent>

            <div className={localClasses.portalItemsActions}>
              <Button
                onClick={() => openMap(item)}
                className={localClasses.portalItemsButton}
                disabled={typeIn(item.type)}
                color="secondary"
                variant="text"
              >
                View Map
              </Button>

              {loading.has(index) ? (
                <div style={{ marginLeft: '8px' }}>
                  <Loader key={item.id} loading height={0} size={20} />
                </div>
              ) : (
                <Button
                  onClick={() => addPortalToGis(item, index)}
                  style={{ marginLeft: '8px' }}
                  className={localClasses.portalItemsButton}
                  disabled={typeIn(item.type)}
                  color="secondary"
                  variant="text"
                  size="small"
                >
                  Add to Map/GIS
                </Button>
              )}
            </div>
          </Card>
        </Grid>
      ))}
    </>
  );

  const Pagination = () => (
    <div className={localClasses.portalPaginationContent}>
      <div className={localClasses.portalPaginationText}>
        Showing {items?.queryParams?.start}-{items?.queryParams?.start + items?.queryParams?.num} of {items?.total}
      </div>
      <div className={localClasses.portalPagination}>
        <Tooltip title="Previous Page">
          <div>
            <Button
              onClick={() => portalsItems(items?.queryParams?.start - items?.queryParams?.num)}
              style={{ marginRight: 5 }}
              disabled={items?.queryParams?.start === 1}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          </div>
        </Tooltip>

        <Tooltip title="Next Page">
          <div>
            <Button
              onClick={() => portalsItems(items?.nextQueryParams?.start)}
              disabled={items?.nextQueryParams?.start === -1}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
  /* */

  return (
    <div className={classes.container}>
      {/* header */}
      <PortalsHeader onClose={() => actions.handleClose()} title={actions?.map?.name} />
      {/* content */}
      <CardComponent noPadding>
        <div className={localClasses.cardComponent}>
          <div className={classes.generalContent}>
            <Grid spacing={1} container>
              {items?.results?.length ? <PortalsContainer /> : <EmptyPortals />}
            </Grid>
          </div>
        </div>
        {/* custom pagination here */}
        <Pagination />
      </CardComponent>
      {/* warning modal */}
      <ModalWarning
        handleClose={() =>
          setWarning((prevState) => {
            const toUpdate = { ...prevState };
            toUpdate.open = false;
            return toUpdate;
          })
        }
        open={warning.open}
        content={warning.content}
      />
      {/* success modal */}
      <ModalSuccess
        onClose={() =>
          setSuccess((prevState) => {
            const toUpdate = { ...prevState };
            toUpdate.open = false;
            return toUpdate;
          })
        }
        open={success.open}
        content={success.content}
        title="Success"
      />
    </div>
  );
};
