import { configureStore } from '@reduxjs/toolkit'; // , getDefaultMiddleware
import adminAdminsReducer from 'slices/adminAdminsReducer';
import creditUsageReducer from 'slices/creditUsageReducer';
import adminTeamsReducer from 'slices/adminTeamsReducer';
import externalContentReducer from 'slices/externalContentReducer';
import externalProjectReducer from 'slices/externalProjectReducer';
import externalTeamReducer from 'slices/externalTeamReducer';
import externalUserReducer from 'slices/externalUserReducer';
import NewProjectReducer from 'slices/NewProjectReducer';
import observationsReducer from 'slices/observationsReducer';
import profileTeamsReducer from 'slices/profileTeamsReducer';
import teamMembersReducer from 'slices/teamMembersReducer';
import teamProjectsReducer from 'slices/teamProjectsReducer';
import failedImportedContent from 'slices/failedImportedContent';
import inspectionReducer from 'slices/inspectionReducer';
import findingReducer from 'slices/findingReducer';
import projectInspectionReducer from 'slices/projectInspectionReducer';
import projectActiveInspectionsReducer from 'slices/projectActiveInspectionsReducer';
import projectAssetActiveInspectionReducer from 'slices/projectAssetActiveInspectionReducer';
import profileInspectionsReducer from 'slices/profileInspectionsReducer';
import projectMapGis from 'slices/projectMapGis';
import linkedFilesReducer from 'slices/linkedFilesReducer';
import membersReducer from 'slices/membersReducer';
import adminProjectReducer from '../slices/adminProjectReducer';
import alertReducer from '../slices/alertReducer';
import allProjectReducer from '../slices/allProjectReducer';
import authReducer from '../slices/authReducer';
import contentAdminReducer from '../slices/contentAdminReducer';
import contentReducer from '../slices/contentReducer';
import organizationReducer from '../slices/organizationReducer';
import profileProjectAssetReducer from '../slices/profileProjectAssetReducer';
import profileProjectReducer from '../slices/profileProjectReducer';
import projectContentReducer from '../slices/projectContentReducer';
import projectPermissionsReducer from '../slices/projectPermissionsReducer';
import projectReducer from '../slices/projectReducer';
import searchReducer from '../slices/search';
import userReducer from '../slices/userReducer';
import mapGisReducer from '../slices/mapGisReducer';
import paymentHistoryReducer from '../slices/paymentHistoryReducer';
import teamsReducer from 'slices/teamsReducer';
import gisPortalsReducer from 'slices/gisPortalsReducer';

/* const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});
 */

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    users: userReducer,
    search: searchReducer,
    adminAdmins: adminAdminsReducer,
    adminTeams: adminTeamsReducer,
    teamMembers: teamMembersReducer,
    teamProjects: teamProjectsReducer,
    organizations: organizationReducer,
    content: contentReducer,
    adminContents: contentAdminReducer,
    adminProjects: adminProjectReducer,
    profileProjects: profileProjectReducer,
    profileProjectAssets: profileProjectAssetReducer,
    profileTeams: profileTeamsReducer,
    projectPermissions: projectPermissionsReducer,
    projects: projectReducer,
    projectContent: projectContentReducer,
    newProject: NewProjectReducer,
    allProjects: allProjectReducer,
    assetObservations: observationsReducer,
    externalUsers: externalUserReducer,
    externalProjects: externalProjectReducer,
    externalTeams: externalTeamReducer,
    externalContents: externalContentReducer,
    failedContent: failedImportedContent,
    adminMapGis: mapGisReducer,
    inspections: inspectionReducer,
    projectInspections: projectInspectionReducer,
    projectActiveInspections: projectActiveInspectionsReducer,
    projectAssetActiveInspections: projectAssetActiveInspectionReducer,
    profileInspections: profileInspectionsReducer,
    findings: findingReducer,
    creditUsage: creditUsageReducer,
    projectMapGis,
    linkedFiles: linkedFilesReducer,
    paymentHistory: paymentHistoryReducer,
    members: membersReducer,
    teams: teamsReducer,
    portals: gisPortalsReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
