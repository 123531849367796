import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    fontWeight: 'normal',
    fontFamily: 'inherit',
    cursor: 'pointer',
    color: theme.palette.primary[200],
  },
  breadcrumb: {
    color: theme.palette.primary[500],
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '5px',
  },
  section: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'inherit',
    paddingTop: '4px',
    color: theme.palette.primary[500],
  },
});
