import { Card } from 'components/Card';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTable, useSortBy } from 'react-table';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { setSelectedTeams } from 'slices/teamsReducer';
import { AdminFilters } from './AdminFilters';
import { AdminDetailPanel } from './AdminDetailPanel';
import PropTypes from 'prop-types';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { AlertTriangleIcon } from 'components/Icons/AlertTriangleIcon';
import OrganizationService from 'services/OrganizationService';
import { useHistory } from 'react-router-dom';

export const Admins = ({ org, onInit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [adminRoles, setAdminRoles] = useState({});

  const { displayPanel, setHeaderPanels } = useSidebar();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (rowData) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{rowData.name}</Typography>
            <Typography variant="caption">{rowData.email}</Typography>
          </Box>
        ),
      },
      {
        Header: 'Roles',
        accessor: 'roles',
      },
      {
        Header: 'Telephone',
        accessor: 'telephone',
      },
    ],
    []
  );

  const fetchAdminsFromOrg = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await OrganizationService.getOrganizationAdminsRoles(org.id, 5);
      const formattedAdmins = data.map((admin) => ({
        id: admin.id,
        name: admin.firstName,
        roles: admin.roles || 'Admin',
        telephone: admin.phone,
        email: admin.email,
      }));
      setAdmins(formattedAdmins);
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setLoading(false);
    }
  }, [org.id]);

  useEffect(() => {
    fetchAdminsFromOrg();
    if (onInit) {
      onInit(fetchAdminsFromOrg);
    }
  }, [fetchAdminsFromOrg, onInit]);

  const table = useTable(
    {
      columns,
      data: admins,
    },
    useSortBy
  );

  const handleSelectedIds = (ids) => {
    setSelectedRows(ids);
    dispatch(setSelectedTeams(ids));
  };

  const handleCopyUuid = (uuid) => {
    setSelectedUuid(uuid);
    setShowCopyModal(true);
  };

  const handleCloseCopyModal = () => {
    setShowCopyModal(false);
    setSelectedUuid(null);
  };

  const handleRowClick = (row) => {
    const admin = {
      name: row.original.name,
      roles: row.original.roles,
      telephone: row.original.telephone,
    };
    setHeaderPanels([
      {
        name: 'adminDetail',
        component: AdminDetailPanel,
        props: { admin },
      },
    ]);
    displayPanel('adminDetail');
  };

  const handleDeleteAdmin = async () => {
    try {
      if (adminToDelete) {
        await OrganizationService.deleteAdmin(adminToDelete.id);
        setAdmins(admins.filter((admin) => admin.id !== adminToDelete.id));
        setShowDeleteConfirm(false);
        setAdminToDelete(null);
      }
    } catch (error) {
      console.error('Error deleting admin:', error);
    }
  };

  const AdminActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => handleCopyUuid(row.original.id)}>Copy UUID</ContextMenuItem>
        <ContextMenuItem onClick={() => history.push(`/sa/tgadmin-create`)}>Add/Edit privileges</ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  return (
    <Card noPadding>
      <AdminFilters
        selectedRows={selectedRows}
        org={org}
        onAdminsUpdate={fetchAdminsFromOrg}
        admins={admins}
        showProjectModal={showProjectModal}
        setShowProjectModal={setShowProjectModal}
        showAdminModal={showAdminModal}
        setShowAdminModal={setShowAdminModal}
        adminRoles={adminRoles}
        setAdminRoles={setAdminRoles}
      />
      <GeneralTable
        table={table}
        selectRow
        handleSelectedIds={handleSelectedIds}
        rowComponent={AdminActions}
        loading={loading}
        onRowClick={handleRowClick}
      />
      {showCopyModal && <CopyUuidAlert id={selectedUuid} handleClose={handleCloseCopyModal} />}

      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)} maxWidth="sm" fullWidth>
        <Box sx={{ mt: 1, ml: 1 }}>
          <AlertTriangleIcon size={28} stroke="#e57373" background="#fff9c4" />
        </Box>
        <DialogTitle variant="h4">Delete {adminToDelete?.name}</DialogTitle>
        <DialogContent>
          <Typography>
            Do you really want to delete {adminToDelete?.name} from the project? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDeleteAdmin} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

Admins.propTypes = {
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onInit: PropTypes.func,
};

Admins.defaultProps = {
  onInit: null,
};
