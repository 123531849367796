import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const FeatureIcon = ({ size }) => (
  <Svg size={size} viewBox="0 0 56 56" fill="none">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" strokeWidth="8" />
    <path
      d="M24 34L17 38V22L24 18M24 34L32 38M24 34V18M32 38L39 34V18L32 22M32 38V22M32 22L24 18"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

FeatureIcon.propTypes = {
  size: PropTypes.number,
};

FeatureIcon.defaultProps = {
  size: 56,
};

/* feature set 2 icon */
export const FeatureSet2Icon = ({ size }) => (
  <Svg size={size} viewBox="0 0 48 48" fill="none">
    <path
      d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
      fill="#039855"
    />
    <path
      d="M34.4168 23.0857V24.0057C34.4156 26.1621 33.6882 28.2604 32.3432 29.9875C30.9983 31.7147 29.1078 32.9782 26.9537 33.5896C24.7996 34.201 22.4973 34.1276 20.3902 33.3803C18.2832 32.633 16.4842 31.2518 15.2616 29.4428C14.039 27.6338 13.4583 25.4938 13.6061 23.342C13.7539 21.1903 14.6223 19.1421 16.0817 17.5028C17.5412 15.8635 19.5135 14.7211 21.7045 14.2459C23.8956 13.7706 26.1879 13.9881 28.2398 14.8657M34.4168 16L24.0002 26.01L20.8752 23.01"
      stroke="#A6F4C5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

FeatureSet2Icon.propTypes = {
  size: PropTypes.number,
};

FeatureSet2Icon.defaultProps = {
  size: 48,
};
