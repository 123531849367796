import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import Service from 'services/MapGisService';
import PropTypes from 'prop-types';

export const TogglePortal = ({ row }) => {
  const [isActive, setIsActive] = useState(row?.isActive);
  const [disabled, setDisabled] = useState(false);

  const handleChange = () => {
    setIsActive(!isActive);
    setDisabled(true);
    // call here the service to activate or deactivate a portal
    Service.activePortal(row?.id, !isActive).then(() => {
      setDisabled(false);
    });
  };

  return (
    <FormControlLabel
      onChange={handleChange}
      control={<Switch color="secondary" />}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      checked={isActive}
      disabled={disabled}
      value="isActive"
    />
  );
};

TogglePortal.propTypes = {
  row: PropTypes.object,
};

TogglePortal.defaultProps = {
  row: null,
};
