import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Service from 'services/MapGisService';

/* get portal list */
export const getPortals = createAsyncThunk('adminGisPortals/getPortals', async (filters) => {
  try {
    const { data } = await Service.getPortals(filters);
    return data;
  } catch (error) {
    throw new Error('Error during request for gis portals...');
  }
});

/* initial state */
const initialState = {
  data: [],
  loading: false,
  error: null,
  totalCount: 0,
  filters: {
    page: 1,
    page_size: 10,
    isOnline: true,
  },
};

// creating the slice
const gisPortalsSlice = createSlice({
  name: 'adminGisPortals',
  initialState,
  reducers: {
    resetGisPortals: () => initialState,
    setPageFilter: (state, { payload }) => {
      state.filters.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPortals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPortals.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.totalCount = payload.meta.totalCount;
      state.loading = false;
    });
    builder.addCase(getPortals.rejected, (state) => {
      state.data = [];
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
  },
});

// export actions
export const { setPageFilter, resetGisPortals } = gisPortalsSlice.actions;

// export reducer
export default gisPortalsSlice.reducer;
