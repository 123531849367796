import PropTypes from 'prop-types';
import React from 'react';
import * as uuid from 'uuid';
import { Button, Typography } from '@mui/material';
import { useStyles } from './styles';

export const SecondaryHeader = ({ title, children, actions, subtitle, newStyle }) => {
  const classes = useStyles();

  return (
    <div
      className={
        newStyle
          ? `${classes.secondaryHeaderContent} ${classes.newSecondaryHeaderContent}`
          : classes.secondaryHeaderContent
      }
    >
      <Typography variant="h4" className={classes.secondaryHeaderText}>
        {title}
      </Typography>
      {children}
      <Typography className={classes.SecondaryHeaderSubtitleText}>{subtitle}</Typography>

      {actions && actions.length > 0 && (
        <div className={classes.buttonsContent}>
          {actions.map((action) => (
            <Button
              key={uuid.v4()}
              style={{ color: action.fill ? action.fill : '#FFFFFF' }}
              sx={
                // eslint-disable-next-line no-nested-ternary
                action?.variant === 'buttonLeft'
                  ? {
                      marginRight: '0!important',
                      borderRadius: '10px 0 0 10px!important',
                    }
                  : action?.variant === 'buttonRight'
                  ? { borderRadius: '0px 10px 10px 0px!important' }
                  : action?.sx
              }
              variant="contained"
              onClick={action.onClick}
              color={action?.color ? action?.color : 'secondary'}
              startIcon={action.icon ? <action.icon size={20} fill={action.fill ? action.fill : '#FFFFFF'} /> : ''}
              className={classes.button}
              disableElevation={action.disableElevation ? action.disableElevation : false}
            >
              {action.text}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

SecondaryHeader.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.instanceOf(Array),
  newStyle: PropTypes.bool,
};

SecondaryHeader.defaultProps = {
  children: null,
  subtitle: '',
  actions: [],
  newStyle: false,
};
