import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography } from '@mui/material';
import { FeatureWarningIcon, CloseIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { If } from 'components/If';
import { useStyles } from 'smartComponents/ModalLinkToMapOrServices/styles';
import { useStyles as useLocalStyles } from './styles';
import Service from 'services/MapGisService';
import PropTypes from 'prop-types';

export const PortalsDeleteModal = ({ onClose, open, portalData }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const localClasses = useLocalStyles();

  /* */
  const closeModals = () => {
    onClose();
  };

  const handleRequest = () => {
    setLoading(true);
    Service.deletePortal(portalData?.id).then(() => {
      onClose({ load: true });
      setLoading(false);
    });
  };
  /* */

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <FeatureWarningIcon size={50} />
          <Button
            onClick={closeModals}
            className={localClasses.headerButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            <CloseIcon size={20} />
          </Button>
        </div>
        <Typography variant="h4" component="div">
          Delete {portalData?.name}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <If cond={loading}>
          <div>
            <Loader loading={loading} height={50} size={32} />
          </div>
        </If>
        <If cond={!loading}>
          <Typography variant="caption" component="div">
            Deleted are moved inthe the &apos;Pending Delete&apos; page for a period designated by the project, Org, or
            Account. During this time the deletion can be cancelled. This also provides time for any parties sharing
            this object to copy it.
          </Typography>
        </If>
      </DialogContent>

      <div className={classes.actions}>
        <div className={classes.actionsContent} style={{ marginRight: 5 }}>
          <Button
            onClick={closeModals}
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
          >
            Cancel
          </Button>
        </div>

        <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
          <Button
            onClick={handleRequest}
            disabled={loading}
            variant="contained"
            color="error"
            fullWidth
            disableElevation
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

PortalsDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  portalData: PropTypes.object,
};

PortalsDeleteModal.defaultProps = {
  open: false,
  portalData: {},
};
