import { makeStyles } from '@mui/styles';
import { Button } from 'components/Button/Button';
import { FormSelect } from 'components/FormSelect';
import { Typography } from 'components/Typography';
import { theme } from 'theme';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  border-bottom: 0.1px solid ${(props) => props.theme.palette.primary[700]};
`;

export const StyledError = styled(Typography)`
  color: ${(props) => props.theme.palette.danger.base};
`;

export const SectionPadding = styled.div`
  padding-top: ${(props) => props.theme.spacing(props.paddingTop || 0)};
  padding-right: ${(props) => props.theme.spacing(props.paddingRight || 0)};
  padding-bottom: ${(props) => props.theme.spacing(props.paddingBottom || 0)};
  padding-left: ${(props) => props.theme.spacing(props.paddingLeft || 0)};
`;

export const ModalRadioGroup = styled.div`
  padding-left: ${(props) => props.theme.spacing(2)};
`;

export const ModalTitleSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => props.theme.spacing(1)};
`;

export const ModalTitleContent = styled.div`
  margin-left: ${(props) => props.theme.spacing(0.5)};
  flex: 1;
`;

export const ModalSelect = styled(FormSelect)`
  margin-top: 15px;
  margin-right: 20px;
`;

export const TransparentButton = styled(Button)`
  background-color: unset;
  font-size: inherit;
  font-weight: inherit;
  justify-content: left;
`;

export const toggleButtonStyles = {
  toggleButton: {
    // backgroundColor: 'black',
    color: 'gray',
    padding: '10px',
    margin: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: 'gray',
    color: 'white',
  },
};

export const useStyles = makeStyles({
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    color: '#ACACAC',
  },
  // -------------------------- dialog actions
  actions: {
    padding: '16px 24px',
    display: 'flex',
    width: '100%',
  },
  actionsContent: {
    display: 'flex',
    width: '100%',
    '& button': {
      fontSize: '16px!important',
      fontWeight: `${500}!important`,
    },
  },
  inputLabel: {
    fontSize: '14px',
    fontFamily: 'inter',
    fontWeight: 'normal',
    color: theme.palette.primary[400],
  },
});
