import React from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { HeartIcon } from 'components/Icons';
import { useStyles } from '../ModalCreditsPurchasing/styles';
import PropTypes from 'prop-types';

export const ModalSuccess = ({ onClose, open, title, content }) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open} maxWidth="xs">
      <DialogContent className={classes.successAlert}>
        <div className={classes.successAlertIcons}>
          <HeartIcon size={20} />
          <IconButton onClick={onClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </IconButton>
        </div>
        <Typography className={classes.successAlertTitle}>{title}</Typography>
        <Typography className={classes.successAlertText}>{content}</Typography>
      </DialogContent>
    </Dialog>
  );
};

ModalSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

ModalSuccess.defaultProps = {
  content: '',
};
