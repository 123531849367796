import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: '95vh',
    bottom: 0,
    backgroundColor: theme.palette.secondary[1300],
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 1000,
    transform: `translateX('110%')`,
    transition: 'transform 0.3s ease-out',
    overflow: 'hidden',
  },
  header: {
    padding: '0 13px',
    paddingBottom: '13px',
    display: 'block',
    backgroundColor: theme.palette.primary[1900],
    boxShadow: '0 1px 4px 0 #000000',
    alignItems: 'center',
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '18px',
      '& div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  generalContent: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.3),
    borderRadius: '8px',
  },
  fullHeight: {
    height: 'calc(100% - 2rem)',
  },
  content: {
    backgroundColor: theme.palette.primary[1300],
    border: `1px solid ${theme.palette.primary[800]}!important`,
    width: '100%',
    borderRadius: 8,
    height: '100%',
  },
  contentHeader: {
    padding: theme.spacing(0.7),
    borderBottom: `1px solid ${theme.palette.primary[800]}`,
  },
  contentExpand: {
    padding: theme.spacing(0.7),
    borderBottom: `1px solid ${theme.palette.primary[800]}`,
    backgroundColor: theme.palette.primary[1400],
  },
  contentCredits: {
    margin: theme.spacing(4.5),
    fontSize: `${100}px!important`,
    textAlign: 'center',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    color: '#acacac',
  },
  headerDetail: {
    padding: '1rem',
    display: 'inline-grid',
    width: '100%',
    gridTemplateColumns: '[col1] auto [col2] auto',
    backgroundColor: theme.palette.primary[1400],
  },
  headerDetailText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  headerDetailButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  contentFooter: {
    textAlign: 'right',
    padding: theme.spacing(0.3),
    backgroundColor: theme.palette.primary[1400],
  },
  tileContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tile: {
    position: 'relative',
  },
  contentCreditsAverage: {
    float: 'right',
  },
  topHeader: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '0.6rem',
    marginBottom: '0.6rem',
    marginRight: '1.6rem',
  },
  topHeaderIcon: {
    color: `${theme.palette.primary[300]}!important`,
    border: `1px solid ${theme.palette.primary[800]}!important`,
  },
  borderTop: {
    borderTopLeftRadius: '8px 8px !important',
    borderTopRightRadius: '8px 8px !important',
  },
  borderBottom: {
    borderBottomLeftRadius: '8px 8px !important',
    borderBottomRightRadius: '8px 8px !important',
  },
  titleOne: {
    fontFamily: 'inter',
    fontSize: '18px!important',
    fontStyle: 'normal',
    color: theme.palette.primary[200],
  },
  titleSecond: {
    fontFamily: 'inter',
    fontSize: '16px',
    fontStyle: 'normal',
    color: theme.palette.primary[500],
  },
  titleSubHeading: {
    fontFamily: 'inter',
    color: theme.palette.primary[400],
    fontSize: '14px',
    fontStyle: 'normal',
  },
  titleBreadcumb: {
    color: theme.palette.primary[500],
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '7px',
  },
});
