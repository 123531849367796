import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  emptyBar: {
    backgroundColor: theme.palette.primary[1700],
    borderTop: 'none!important',
    padding: '1rem',
    display: 'flex',
    height: '62px',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    color: theme.palette.primary[1800],
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.primary[200],
    paddingTop: '6px',
  },
  subTitle: {
    color: theme.palette.primary[400],
    fontSize: '14px',
    fontWeight: 500,
    paddingTop: '5px',
  },
  inputLabel: {
    fontSize: '14px',
    color: theme.palette.primary[400],
    fontWeight: 500,
  },
  actions: {
    paddingTop: '12px',
    display: 'flex',
    width: '100%',
  },
  actionsButton: {
    display: 'flex',
    width: '100%',
    '& button': {
      fontSize: '16px!important',
      fontWeight: `${500}!important`,
    },
  },
  loader: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 198px)',
    borderRadius: 8,
    overflow: 'hidden',
  },
  map: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  mapDetail: {
    height: 'auto',
    width: '100%',
    position: 'relative',
  },
  mapDetailActions: {
    position: 'absolute',
    bottom: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    width: '100%',
  },
  mapDetailHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '5px',
  },
  cardComponent: {
    height: 'calc(-122px + 100vh)',
    overflow: 'auto',
  },
  portalItemsThumbnail: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  portalItemsActions: {
    display: 'flex',
    WebkitAlignItems: 'center',
    alignItems: 'center',
    padding: '8px',
  },
  portalItemsButton: {
    minWidth: '64px',
    fontWeight: 500,
    letterSpacing: '0.02857em',
    padding: '4px 5px',
    margin: 0,
  },
  portalPaginationContent: {
    padding: '1rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary[1900],
    marginBottom: '5px',
  },
  portalPaginationText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  portalPagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  warningModalButtons: {
    marginTop: '10px',
    '& button': {
      marginTop: '10px',
    },
  },
});
