import ApiService from './ApiService';

export default class TeamService {
  static getTeams = (filters) => ApiService.get('/teams', filters);

  static getTeam = async (teamId) => ApiService.get(`/teams/${teamId}`);

  static getUserRole = async (teamId, memberId) => ApiService.get(`/teams/${teamId}/users/${memberId}/roles`);

  static createTeam = async (team) => ApiService.post(`/teams`, team);

  static addTeamMember = async (teamId, userId, roles) => ApiService.post(`/teams/${teamId}/users`, { userId, roles });

  static getTeamAccounts = (userId) => ApiService.get('/teams/all/accounts', { userId });

  static getTeamOrganizations = (userId) => ApiService.get('/teams/all/organizations', { userId });

  static getTeamAccountsOrganizations = (filters) => ApiService.get('/teams/all/accountsOrganizations', filters);

  /**
   * Update the team roles for a user
   *
   * @param {string} teamId
   * @param {string} memberId
   * @param {number[]} roles
   * @returns
   */
  static updateUserRoles = async (teamId, memberId, roles) =>
    ApiService.post(`/teams/${teamId}/users/${memberId}/roles`, { roles });

  /**
   * Update the team name and description
   *
   * @param {string} teamId
   * @param {object} data
   * @returns
   */
  static updateTeam = async (teamId, data) => ApiService.patch(`/teams/${teamId}`, data);

  /**
   * Get all the teams for an organization
   *
   * @param {string} orgId
   * @returns
   */
  static getTeamsOrganizations = (orgId) => ApiService.get(`/teams/organization/${orgId}`);

  /**
   * Delete a team from organization
   *
   * @param {string} teamId
   * @returns
   */
  static deleteTeam = async (teamId) => ApiService.delete(`/teams/${teamId}`);

  /**
   * Add new team to organization
   *
   * @param {object} data
   * @param {string} data.orgId
   * @returns
   */
  static addTeamOrganization = async (data) => ApiService.post('/teams', data);

  /**
   * Bulk delete teams from organization
   *
   * @param {string[]} teamIds
   * @returns
   */
  static bulkDeleteTeams = async (teamIds) => ApiService.post('/teams/delete', { teamIds });

  /**
   * Duplicate teams
   *
   * @param {string[]} teamIds - Array of team IDs to duplicate
   * @returns
   */
  static duplicateTeams = async (teamIds) => ApiService.post('/teams/duplicate', { teamIds });

  /**
   * Transfer teams
   *
   * @param {object} data - Object with team IDs and target organization ID
   * @param {string[]} data.teamIds - Array of team IDs to transfer
   * @param {string} data.targetOrganizationId - Target organization ID
   * @returns
   */
  static transferTeams = async (data) => ApiService.post('/teams/transfer', data);

  /**
   * Combine teams
   *
   * @param {object} data - Object with source team IDs and target team ID
   * @param {string[]} data.sourceTeamIds - Array of source team IDs to combine
   * @param {string} data.targetTeamId - Target team ID
   * @returns
   */
  static combineTeams = async (data) => ApiService.post('/teams/combine', data);
}
