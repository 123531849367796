import React, { useEffect, useMemo, useState, useContext } from 'react';
import Portal from '@arcgis/core/portal/Portal';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { NewAdminHeaderTitle } from 'components/AdminHeaderTitle/NewAdminHeaderTitle';
import { Card } from 'components/Card';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { CirclePlusIcon } from 'components/Icons';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { Loader } from 'components/Loader';
import { MapGisPortalsActions } from 'components/MapGisPortalsActions/MapGisPortalsActions';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ModalWarning } from 'smartComponents/ModalWarning';
import { getPortals, setPageFilter, resetGisPortals } from 'slices/gisPortalsReducer';
import { TogglePortal } from './TogglePortal';
import { PortalsFormModal } from './PortalsFormModal';
import { PortalsItems } from './PortalsItems';
import { PortalsDeleteModal } from './PortalsDeleteModal';
import { useStyles } from './styles';
import SplashImage from 'images/ContentSplash.png';
import PropTypes from 'prop-types';
import moment from 'moment';

// debounce
let debounceTimeout = null;

const tableColumns = () => [
  {
    Header: 'Turn On/Off',
    accessor: (row) => <TogglePortal row={row} />,
  },
  {
    Header: 'Title',
    accessor: (row) => row.name,
  },
  {
    Header: 'URL',
    accessor: (row) => (
      <div
        style={{
          width: '250px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'inline-block',
          whiteSpace: 'nowrap',
        }}
      >
        {row.url}
      </div>
    ),
  },
  {
    Header: 'Added By',
    accessor: (row) => {
      const { user } = row;
      return `${user.firstName} ${user.lastName}`;
    },
  },
  {
    Header: 'Added Date',
    accessor: (row) => moment(row.createdAt).format('MM/DD/YYYY'),
  },
];

export const Portals = ({ isOnline }) => {
  const [portalsModal, setPortalsModal] = useState(false);
  const [portalData, setPortalData] = useState(null);
  const [portalDeleteModal, setPortalDeleteModal] = useState(false);
  const [warning, setWarning] = useState({ open: false, content: '', title: '' });
  const [map, setMap] = useState(null);
  const { data, totalCount, filters, loading } = useSelector((state) => state.portals);
  const { setActivePanel, setHeaderPanels, setFullWidth } = useContext(SidebarDetailContext);
  const columns = useMemo(() => tableColumns(), []);
  const dispatch = useDispatch();
  const classes = useStyles();

  // declare table config
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: filters.page_size,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  /* */
  const closeAlert = () => {
    setWarning((prevState) => {
      const toUpdate = { ...prevState };
      toUpdate.open = false;
      return toUpdate;
    });
  };

  const openSideBar = (row) => {
    const portal = new Portal({
      authMode: 'immediate',
      url: row?.url,
    });

    portal
      .load()
      .then(() => {
        // if the authentication is correct then display the sidebar with the items
        const portalsItems = 'portalsItems';
        setHeaderPanels([
          {
            name: portalsItems,
            component: PortalsItems,
            actions: {
              handleClose: () => {
                setActivePanel('');
              },
              map: row,
              portal,
            },
          },
        ]);
        setFullWidth(true);
        setActivePanel(portalsItems);
      })
      .catch((error) => {
        if (error?.message !== 'ABORTED') {
          setWarning({ open: true, content: error?.message, title: error?.details?.error?.message });
        }
      });
  };

  const signIn = (row, destroyCredentials = false) => {
    // validate if is necessary destroy the ESRI credentials
    const credential = IdentityManager.findCredential(row?.url);
    if (destroyCredentials && credential) {
      if (typeof credential?.oAuthState !== 'undefined') {
        credential.destroy();
      }
      IdentityManager.destroyCredentials();
      closeAlert();
    }

    if (isOnline) {
      openSideBar(row);
    } else {
      // validate the session
      const oAuth = new OAuthInfo({
        appId: row?.clientId || 'Ht75vcUiel5NanH9',
        portalUrl: row?.url,
      });

      // save the oauth
      IdentityManager.registerOAuthInfos([oAuth]);

      // validate the session
      IdentityManager.checkSignInStatus(`${oAuth.portalUrl}/sharing`)
        .then(() => openSideBar(row))
        .catch(() => {
          IdentityManager.getCredential(`${oAuth.portalUrl}/sharing`, { oAuthPopupConfirmation: false }).then(() => {
            window.console.log('Creating....');
          });
        });
    }
  };

  const handleActions = (action, row) => {
    setMap(row);
    if (action === 'edit') {
      setPortalData({ action, row });
      setPortalsModal(true);
    }
    if (action === 'map') {
      signIn(row);
    }
    if (action === 'delete') {
      setPortalDeleteModal(true);
    }
  };

  const loadPortals = () => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const toFilters = { ...filters };
      toFilters.isOnline = isOnline;
      dispatch(resetGisPortals());
      dispatch(getPortals(toFilters));
    }, 300);
  };
  /* */

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  // get portals
  useEffect(() => {
    loadPortals();
  }, [filters, isOnline]);

  const PortalsContainer = () => (
    <>
      <div className={classes.emptyBar} />
      <GeneralTable
        table={table}
        rowComponent={MapGisPortalsActions}
        selectedRow={map?.id}
        actionFunction={(action, row) => handleActions(action, row)}
        handleRowClick={(row) => handleActions('map', row?.original)}
        arcgis
      />
      <TablePagination table={table} />
    </>
  );

  const EmptyPortalsContainer = () => (
    <WidgetSplash image={SplashImage} alt="Portals" title="There are currently no portals." />
  );

  /* loading */
  if (loading && !data.length) {
    return (
      <AccountSettingsContainer
        title={<NewAdminHeaderTitle section="GIS Map/Services" subSection={isOnline ? 'ArcGIS Online' : 'Portals'} />}
        actions={[
          {
            icon: CirclePlusIcon,
            text: isOnline ? 'New ArcGIS Online' : 'New Portal',
            fill: '#000000',
            disableElevation: true,
            onClick: () => {
              setPortalData(null);
              setPortalsModal(true);
            },
          },
        ]}
        newStyle
        showSidebar
      >
        <Loader loading={loading} height={250} />
      </AccountSettingsContainer>
    );
  }

  return (
    <AccountSettingsContainer
      title={<NewAdminHeaderTitle section="GIS Map/Services" subSection={isOnline ? 'ArcGIS Online' : 'Portals'} />}
      actions={[
        {
          icon: CirclePlusIcon,
          text: isOnline ? 'New ArcGIS Online' : 'New Portal',
          fill: '#000000',
          disableElevation: true,
          onClick: () => {
            setPortalData(null);
            setPortalsModal(true);
          },
        },
      ]}
      newStyle
      showSidebar
    >
      <Card noPadding>{data.length ? <PortalsContainer /> : <EmptyPortalsContainer />}</Card>
      {/* modal with the portal form */}
      <PortalsFormModal
        onClose={(value) => {
          setPortalData(null);
          setPortalsModal(false);
          if (value?.load) {
            loadPortals();
          }
        }}
        open={portalsModal}
        isOnline={isOnline}
        portalData={portalData}
      />
      {/* modal to delete a portal */}
      <PortalsDeleteModal
        onClose={(value) => {
          setPortalData(null);
          setPortalDeleteModal(false);
          if (value?.load) {
            loadPortals();
          }
        }}
        open={portalDeleteModal}
        portalData={map}
      />
      {/* warning modal */}
      <ModalWarning
        handleClose={closeAlert}
        open={warning.open}
        title={warning.title}
        content={
          <>
            <Typography>{warning.content}</Typography>
            <div className={classes.warningModalButtons}>
              <Typography variant="subtitle2">Would you like to sign in anyway?</Typography>
              <Button onClick={closeAlert} style={{ marginRight: 8 }} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={() => signIn(map, true)} color="secondary" variant="outlined">
                Continue
              </Button>
            </div>
          </>
        }
      />
    </AccountSettingsContainer>
  );
};

Portals.propTypes = {
  isOnline: PropTypes.bool.isRequired,
};
