import React, { useState, useEffect } from 'react';
import { PermGuard } from 'components/Guards/PermGuard';
import { Header } from 'components/Header';
import { Nav } from 'components/Nav';
import { NavItem } from 'components/NavItem';
import { PrivateRoute } from 'components/PrivateRoute';
import { SidebarProvider } from 'components/SidebarProvider/SidebarProvider';
import { UploadStatusWidget } from 'components/UploadStatusWidget/UploadStatusWidget';
import { Permissions as Perms } from 'lib/permissions';
import { PermissionsValidator } from 'lib/permValidator';
import { Admins } from 'pages/Admin/Admins';
import { Permissions } from 'pages/Admin/Admins/Permissions';
import { Content } from 'pages/Admin/Content';
import { Dashboard } from 'pages/Admin/Dashboard/Dashboard';
import { Organizations } from 'pages/Admin/Organizations/Organizations';
import { Projects as AdminProjects } from 'pages/Admin/Projects';
import { ParticipantCoordination } from 'pages/Admin/Projects/ParticipantCoordination';
import { ProjectContent } from 'pages/Admin/Projects/ProjectContent';
import { AdminSecurity } from 'pages/Admin/Settings';
import { Teams } from 'pages/Admin/Teams';
import { Users } from 'pages/Admin/Users';
import { FaqPage } from 'pages/FaqPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { NotAllowed } from 'pages/NotAllowed';
import { ProfileContent } from 'pages/Profile/Content';
import { ProfileDashboard } from 'pages/Profile/Dashboard';
import { ProfileProjects } from 'pages/Profile/Projects';
import { ProfileSecurity } from 'pages/Profile/Security';
import { SingleProject } from 'pages/Profile/SingleProject';
import { ProjectVideoPlayer } from 'pages/Profile/SingleProject/ProjectVideoPlayer/ProjectVideoPlayer';
import { ProfileTeams } from 'pages/Profile/Teams';
import { Projects } from 'pages/Projects';
import { SearchPage } from 'pages/Search/SearchPage/SearchPage';
import { TeamsDashboard } from 'pages/Teams/Dashboard';
import { TeamsMembers } from 'pages/Teams/Members';
import { TeamProjects } from 'pages/Teams/Projects';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CreateContent } from 'components/CreateContent';
import { ServerError } from 'pages/ServerError/ServerError';
import { ExternalUsers } from 'pages/Admin/ExternalUsers/ExternalUsers';
import { ExternalTeams } from 'pages/Admin/ExternalTeams/ExternalTeams';
import { ExternalProjects } from 'pages/Admin/ExternalProjects/ExternalProjects';
import { ExternalContent } from 'pages/Admin/ExternalContent/ExternalContent';
import { SSO } from 'pages/Admin/SSO';
import { MapGis } from 'pages/Admin/MapGis';
import { FailedImportedContent } from 'pages/Admin/FailedImportedContent/FailedImportedContent';
import { AdminShieldIcon, LevelsIcon, LayersIcon, HelpIcon, SearchIcon } from 'components/Icons';
import { SidebarProviderDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { Inspections } from 'pages/Inspections/Inspections';
import { setMenu } from 'slices/authReducer';
import { ProfileInspections } from 'pages/Profile/Inspections/ProfileInspections';
import { useStyles } from './styles';
import { Accounting } from 'pages/Admin/Billing/Accounting';
import { SuperAdminPanel } from 'components/SuperAdminPanel/SuperAdminPanel';
import { SuperAdminButton } from 'components/SuperAdminButton/SuperAdminButton';
import { Portals } from 'pages/Admin/MapGisPortals/Portals';
import Auth from '../../lib/auth';

const adminSectionPerms = [
  Perms.ACCOUNT_DASHBOARD,
  Perms.ACCOUNT_USER_GRANT,
  Perms.ORG_MODIFY,
  Perms.ACCOUNT_USER_MODIFY,
  Perms.TEAM_DELETE,
  Perms.TEAM_MODIFY,
  Perms.PROJ_MODIFY,
  Perms.SUPERADMIN_BILLING,
  Perms.PROJ_DELETE,
  Perms.PROJ_MODIFY_CONTENT,
  Perms.ACCOUNT_MODIFY,
];

const adminRoutePermissions = {
  overview: {
    perms: [Perms.ACCOUNT_DASHBOARD],
    path: 'admin/',
  },
  admins: {
    path: '/admin/admins',
    perms: [Perms.ACCOUNT_USER_GRANT],
  },
  orgs: {
    path: '/admin/organizations',
    perms: [Perms.ORG_MODIFY],
  },
  users: {
    path: '/admin/users',
    perms: [Perms.ACCOUNT_USER_MODIFY, Perms.ACCOUNT_USER_GRANT],
  },
  teams: {
    path: '/admin/teams',
    perms: [Perms.TEAM_DELETE, Perms.TEAM_MODIFY, Perms.ORG_TEAM_CREATE],
  },
  projects: {
    path: '/admin/projects',
    perms: [Perms.PROJ_MODIFY, Perms.ORG_PROJ_CREATE],
  },
  content: {
    path: '/admin/content',
    perms: [Perms.PROJ_MODIFY_CONTENT],
  },
  security: {
    path: '/admin/settings',
    perms: [Perms.ACCOUNT_MODIFY],
  },
  externalUsers: {
    path: '/admin/external/users',
    perms: [Perms.ACCOUNT_USER_GRANT],
  },
  externalProjects: {
    path: '/admin/external/projets',
    perms: [Perms.ACCOUNT_USER_GRANT],
  },
  externalTeams: {
    path: '/admin/external/teams',
    perms: [Perms.ACCOUNT_USER_GRANT],
  },
  externalContent: {
    path: '/admin/external/content',
    perms: [Perms.ACCOUNT_USER_GRANT],
  },
  ssoSettings: {
    path: '/admin/sso',
    perms: [Perms.ACCOUNT_MODIFY],
  },
  inspections: {
    path: '/admin/inspections',
    perms: [Perms.ACCOUNT_MODIFY],
  },
  mapGisServices: {
    path: '/admin/map/gis',
    perms: [Perms.ACCOUNT_MODIFY],
  },
  accounting: {
    path: '/admin/accounting',
    perms: [Perms.ACCOUNT_BILLING],
  },
};

export const AppRouteContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const [showFailed, setShowFailed] = useState(false);
  const [superAdminPanelOpen, setSuperAdminPanelOpen] = useState(false);

  const userPerms = useSelector((state) => state.auth.permissions.userPerms);
  const acct = useSelector((state) => state.auth.permissions.account || '');
  const menu = useSelector((state) => state.auth.menu);

  const DefaultRouteRedirect = () => {
    const permKeys = Object.keys(adminRoutePermissions);

    for (let i = 0; i < permKeys.length; i += 1) {
      const permObj = adminRoutePermissions[permKeys[i]];

      if (permObj.perms.every((perm) => userPerms[perm])) {
        return <Redirect to={permObj.path} />;
      }
    }

    return <Redirect to="/401" />;
  };

  const dukeAcctDev = '5CE9176D-0826-4886-820D-6FAAA3FAE851';
  const dukeAcctProd = '16AB130D-C3BB-4456-846E-C2B0F4EFFB24';
  const accountsEnabled = [dukeAcctProd, dukeAcctDev];

  const getAcct = async () => {
    const items = Object.keys(acct);
    // eslint-disable-next-line
    console.warn(items[0]);

    setShowFailed(accountsEnabled.includes(items[0]));
  };

  useEffect(() => {
    getAcct();
  });

  const toggleMenu = (type) => {
    dispatch(setMenu({ [type]: !menu[type] }));
  };

  return (
    <SidebarProvider>
      <SidebarProviderDetail>
        <div className={classes.layoutApp}>
          <Header />
          <Nav>
            <ul className={classes.navMain}>
              <NavItem
                icon={LevelsIcon}
                link="/profile"
                active={pathname.indexOf('/profile') === 0}
                onClick={() => toggleMenu('profile')}
              >
                Home
              </NavItem>
              <NavItem icon={LayersIcon} link="/projects" active={pathname.indexOf('/projects') === 0}>
                Projects
              </NavItem>
              <NavItem icon={SearchIcon} link="/search" active={pathname.indexOf('/search') === 0}>
                Search
              </NavItem>
            </ul>

            <ul className={classes.navSecondary}>
              <PermGuard scopes={adminSectionPerms}>
                <NavItem
                  icon={AdminShieldIcon}
                  link="/admin"
                  active={pathname.indexOf('/admin') === 0}
                  onClick={() => toggleMenu('admin')}
                >
                  Admin
                </NavItem>
              </PermGuard>
              <NavItem icon={HelpIcon} link="/faq" active={pathname.indexOf('/faq') === 0}>
                Help
              </NavItem>
              {Auth.isTgAdmin() && (
                <SuperAdminButton active={superAdminPanelOpen} onClick={() => setSuperAdminPanelOpen(true)} />
              )}
            </ul>
          </Nav>
          {Auth.isTgAdmin() && (
            <SuperAdminPanel open={superAdminPanelOpen} onClose={() => setSuperAdminPanelOpen(false)} />
          )}
          <Switch>
            <PrivateRoute path="/teams/:teamId">
              <Switch>
                <Route path="/teams/:teamId" exact>
                  <TeamsDashboard />
                </Route>
                <Route path="/teams/:teamId/members">
                  <TeamsMembers />
                </Route>
                <Route path="/teams/:teamId/projects">
                  <TeamProjects />
                </Route>
                <Redirect to="/404" />
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/projects">
              <Switch>
                <Route path="/projects" exact>
                  <Projects />
                </Route>
                <Route path="/projects/:projId/participant">
                  <ParticipantCoordination />
                </Route>
                <PrivateRoute path="/projects/:projId/content" scopes={adminRoutePermissions.projects.perms} exact>
                  <ProjectContent />
                </PrivateRoute>
                <Route path="/projects/:projId/content/create" exact>
                  <CreateContent type="account" hideMenu />
                </Route>
                <Route path="/projects/:projId/content/:assetId" exact>
                  <ProjectVideoPlayer />
                </Route>
                <Route path="/projects/:projId" exact>
                  <SingleProject />
                </Route>
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/profile">
              <Switch>
                <Route path="/profile" exact>
                  <ProfileDashboard />
                </Route>
                <Route path="/profile/projects/:projId/content/create" exact>
                  <CreateContent type="profile" />
                </Route>
                <Route path="/profile/projects/:projId/content/:assetId">
                  <ProjectVideoPlayer />
                </Route>
                <Route path="/profile/projects/:projId">
                  <SingleProject />
                </Route>
                <Route path="/profile/projects" exact>
                  <ProfileProjects />
                </Route>
                <Route path="/profile/inspections" exact>
                  <ProfileInspections />
                </Route>
                <Route path="/profile/teams">
                  <ProfileTeams />
                </Route>
                <Route path="/profile/content">
                  <ProfileContent />
                </Route>
                <Route path="/profile/security">
                  <ProfileSecurity />
                </Route>
                <Redirect to="/404" />
              </Switch>
            </PrivateRoute>
            <PrivateRoute path="/search">
              <SearchPage />
            </PrivateRoute>
            <PrivateRoute path="/admin">
              <Switch>
                <Route path="/admin" exact>
                  {PermissionsValidator.validate({ scopes: adminRoutePermissions.overview.perms }) ? (
                    <Dashboard />
                  ) : (
                    <DefaultRouteRedirect />
                  )}
                </Route>
                <PrivateRoute path="/admin/admins/:userId/permissions" scopes={adminRoutePermissions.admins.perms}>
                  <Permissions />
                </PrivateRoute>
                <PrivateRoute path="/admin/users/:userId/permissions" scopes={adminRoutePermissions.users.perms}>
                  <Permissions />
                </PrivateRoute>
                <PrivateRoute path="/admin/admins" scopes={adminRoutePermissions.admins.perms}>
                  <Admins />
                </PrivateRoute>
                <PrivateRoute path="/admin/organizations" scopes={adminRoutePermissions.orgs.perms}>
                  <Organizations />
                </PrivateRoute>
                <PrivateRoute path="/admin/users" scopes={adminRoutePermissions.users.perms}>
                  <Users />
                </PrivateRoute>
                <PrivateRoute path="/admin/teams" scopes={adminRoutePermissions.teams.perms}>
                  <Teams />
                </PrivateRoute>
                <PrivateRoute path="/admin/projects/:projId/content/create" exact>
                  <CreateContent type="account" />
                </PrivateRoute>
                <PrivateRoute path="/admin/external/projects/:projId/content/create" exact>
                  <CreateContent type="account" />
                </PrivateRoute>
                <PrivateRoute
                  path="/admin/projects/:projId/content/:assetId"
                  scopes={adminRoutePermissions.projects.perms}
                  exact
                >
                  <ProjectVideoPlayer />
                </PrivateRoute>
                <PrivateRoute
                  path="/admin/external/projects/:projId/content/:assetId"
                  scopes={adminRoutePermissions.projects.perms}
                  exact
                >
                  <ProjectVideoPlayer />
                </PrivateRoute>
                <PrivateRoute path="/admin/projects/:projId" scopes={adminRoutePermissions.projects.perms}>
                  <SingleProject />
                </PrivateRoute>
                <PrivateRoute path="/admin/external/projects/:projId" scopes={adminRoutePermissions.projects.perms}>
                  <SingleProject />
                </PrivateRoute>
                <PrivateRoute path="/admin/projects" scopes={adminRoutePermissions.projects.perms}>
                  <AdminProjects />
                </PrivateRoute>
                <PrivateRoute path="/admin/content" scopes={adminRoutePermissions.content.perms}>
                  <Content />
                </PrivateRoute>
                {showFailed && (
                  <PrivateRoute path="/admin/failed-imported-content" scopes={adminRoutePermissions.projects.perms}>
                    <FailedImportedContent />
                  </PrivateRoute>
                )}
                <PrivateRoute path="/admin/settings" scopes={adminRoutePermissions.security.perms}>
                  <AdminSecurity />
                </PrivateRoute>
                <PrivateRoute path="/admin/external/users" scopes={adminRoutePermissions.externalUsers.perms}>
                  <ExternalUsers />
                </PrivateRoute>
                <PrivateRoute path="/admin/external/projects" scopes={adminRoutePermissions.externalProjects.perms}>
                  <ExternalProjects />
                </PrivateRoute>
                <PrivateRoute path="/admin/external/teams" scopes={adminRoutePermissions.externalTeams.perms}>
                  <ExternalTeams />
                </PrivateRoute>
                <PrivateRoute path="/admin/external/content" scopes={adminRoutePermissions.externalContent.perms}>
                  <ExternalContent />
                </PrivateRoute>
                <PrivateRoute path="/admin/sso" scopes={adminRoutePermissions.ssoSettings.perms}>
                  <SSO />
                </PrivateRoute>
                <PrivateRoute path="/admin/map/services" scopes={adminRoutePermissions.mapGisServices.perms}>
                  <MapGis />
                </PrivateRoute>
                <PrivateRoute path="/admin/map/arcgis" scopes={adminRoutePermissions.mapGisServices.perms}>
                  <Portals isOnline />
                </PrivateRoute>
                <PrivateRoute path="/admin/map/portals" scopes={adminRoutePermissions.mapGisServices.perms}>
                  <Portals isOnline={false} />
                </PrivateRoute>
                <PrivateRoute path="/admin/project/:projId/permissions" scopes={adminRoutePermissions.projects.perms}>
                  <ParticipantCoordination />
                </PrivateRoute>
                <PrivateRoute path="/admin/inspections" scopes={adminRoutePermissions.inspections.perms}>
                  <Inspections />
                </PrivateRoute>
                {(process.env.REACT_APP_HIDE_BILLING === false || process.env.REACT_APP_HIDE_BILLING === 'false') && (
                  <>
                    <PrivateRoute path="/admin/accounting" scopes={adminRoutePermissions.accounting.perms} exact>
                      <Accounting />
                    </PrivateRoute>
                  </>
                )}
                <Redirect to="/401" />
              </Switch>
            </PrivateRoute>
            <Route path="/500" component={ServerError} />
            <Route path="/404" component={NotFoundPage} />
            <Route path="/401" component={NotAllowed} />
            <Route path="/faq" component={FaqPage} />
            <Redirect to="/404" />
          </Switch>
        </div>
        <UploadStatusWidget />
      </SidebarProviderDetail>
    </SidebarProvider>
  );
};
