import ApiService from './ApiService';

const path = '/arcgis';

export default class MapGisService {
  static getMapGisServices = (filters) => ApiService.get('/gis', filters);

  static getMapGisService = async (id) => ApiService.get(`/gis/${id}`);

  static saveMapGisService = (data) => ApiService.post(path, data);

  static activateMapGisService = async (id, active) => ApiService.patch(`${path}/${id}/activate`, { active });

  static getMapGisServicesAll = (filters) => ApiService.get(path, filters);

  /**
   * Update the Map GIS or External Map information.
   *
   * @param {string} id
   * @param {string[]} data
   * @returns
   */
  static updateMapGisService = async (id, data) => ApiService.patch(`${path}/${id}`, data);

  static deleteMap = async (id) => ApiService.delete(`${path}/${id}/delete`);

  /* PORTALS */

  /* get portal list */
  static getPortals = (filters) => ApiService.get(`${path}/portals/all`, { ...filters });

  /* new portal */
  static createPortal = (body) => ApiService.post(`${path}/portals`, { ...body });

  /* update portal */
  static updatePortal = (id, body) => ApiService.put(`${path}/portals/${id}`, { ...body });

  /* delete portal */
  static deletePortal = (id) => ApiService.delete(`${path}/portals/${id}`);

  /* active/deactive portal */
  static activePortal = (id, active) => ApiService.patch(`${path}/portals/${id}`, { active });
}
