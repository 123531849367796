/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FilterIcon, SearchIcon, AlertTriangleIcon } from 'components/Icons';
import { MemberRolesModal } from '../Members/MemberRolesModal';
import { setFilters } from 'slices/projectReducer';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { SearchPanel } from './SearchPanel/SearchPanel';
import OrganizationService from 'services/OrganizationService';
import ProjectService from 'services/ProjectService';
import TeamsService from 'services/TeamService';
import { ModalTransferMembers } from '../Members/ModalTransferMembers';

const roleOptions = [
  { value: 'project', label: 'Add/Remove from Project' },
  { value: 'team', label: 'Add/Remove from Team' },
  { value: 'duplicate', label: 'Duplicate Teams' },
  { value: 'transfer', label: 'Transfer Orgs' },
  { value: 'combine', label: 'Combine Teams' },
  { value: 'delete', label: 'Delete Teams' },
];

export const ProjectsFilters = ({
  selectedRows = [],
  org,
  onTeamsUpdate,
  members,
  showProjectModal,
  setShowProjectModal,
  showTeamModal,
  setShowTeamModal,
  teamRoles,
  setTeamRoles,
}) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.teams);
  const [selectedRole, setSelectedRole] = useState('all');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teams, setTeams] = useState([]);
  const [showTransferConfirm, setShowTransferConfirm] = useState(false);
  const [orgList, setOrgList] = useState([]);

  const rolesMapping = {
    participantCoordinator: ['proj:part_coord', 12],
    contentCoordinator: ['proj:content_coord', 11],
    projectAdmin: ['proj:admin', 10],
    memberOnly: ['org:part', 9],
  };

  const fetchProjects = async () => {
    try {
      const {
        data: { data },
      } = await ProjectService.getProjects({ orgId: { eq: org.id } });

      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setProjects([]);
    }
  };

  const fetchTeams = async () => {
    try {
      const {
        data: { data },
      } = await TeamsService.getTeamsOrganizations(org.id);

      setTeams(data);
    } catch (error) {
      console.error('Error fetching teams:', error);
      setTeams([]);
    }
  };

  const fetchOrgList = async () => {
    try {
      const {
        data: { data },
      } = await OrganizationService.getOrganizations();

      setOrgList(data);
    } catch (error) {
      console.error('Error fetching orgs:', error);
      setOrgList([]);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchTeams();
    fetchOrgList();
  }, []);

  const { displayPanel, setHeaderPanels } = useSidebar({
    open: false,
    config: {
      header: [],
      search: {
        show: true,
        component: SearchPanel,
      },
    },
  });

  const handleOpenFilters = () => {
    setHeaderPanels([
      {
        name: 'search',
        component: SearchPanel,
      },
    ]);
    displayPanel('search');
  };

  const filterOptions = (value) => {
    dispatch(
      setFilters({
        search: value,
      })
    );
  };

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedRole(value);

    switch (value) {
      case 'project':
        if (selectedRows.length > 0) {
          const emptyRoles = {};
          selectedRows.forEach((row) => {
            emptyRoles[row] = { memberOnly: true };
          });
          setTeamRoles(emptyRoles);
          setShowProjectModal(true);
        }
        break;
      case 'team':
        // eslint-disable-next-line no-case-declarations
        const emptyRolesTeam = {};
        selectedRows.forEach((row) => {
          emptyRolesTeam[row] = { memberOnly: true };
        });
        setTeamRoles(emptyRolesTeam);
        setShowTeamModal(true);
        break;
      case 'delete':
        if (selectedRows.length > 0) {
          setShowDeleteConfirm(true);
        }
        break;
      case 'transfer':
        if (selectedRows.length > 0) {
          setShowTransferConfirm(true);
        }
        break;
      default:
        break;
    }
  };

  const handleMemberRoleChange = (memberId, roleType) => {
    setTeamRoles((prev) => {
      const updatedRoles = { ...prev[memberId] };
      if (!prev[memberId]?.[roleType]) {
        updatedRoles[roleType] = true;
      } else {
        delete updatedRoles[roleType];
      }
      return {
        ...prev,
        [memberId]: { memberOnly: true, ...updatedRoles },
      };
    });
  };

  const updateMemberRoles = (projectId) => {
    const membersObj = {};
    selectedRows.forEach((row) => {
      const user = members.find((member) => member.id === row);
      const userRoles = user.grants
        .filter(({ objectType, objectId }) => objectType === 'PROJ' && objectId === projectId)
        .reduce((acc, { roleId }) => {
          const foundRole = Object.entries(rolesMapping).find(([, value]) => value[1] === roleId);
          if (foundRole) {
            const [roleKey] = foundRole;
            acc[roleKey] = true;
          }
          return acc;
        }, {});

      membersObj[user.id] = { memberOnly: true, ...userRoles };
    });
    setTeamRoles(membersObj);
  };

  const handleCloseProjectModal = () => {
    setShowProjectModal(false);
    setSelectedRole('all');
    setSelectedProject(null);
    setTeamRoles({});
  };

  const handleCloseTeamModal = () => {
    setShowTeamModal(false);
    setSelectedRole('all');
    setSelectedTeam(null);
    setTeamRoles({});
  };

  const handleTeamChange = (event, value) => {
    setSelectedTeam(value);
    if (value) {
      updateMemberRoles(value.id);
    } else {
      // reset roles when no team is selected
      const emptyRoles = {};
      selectedRows.forEach((row) => {
        emptyRoles[row] = { memberOnly: true };
      });
      setTeamRoles(emptyRoles);
    }
  };

  const handleConfirmTeamChanges = async () => {
    if (!selectedTeam) {
      // TODO: show error message that team is required
      return;
    }

    try {
      // format the data for the API
      const requestData = {
        teamId: selectedTeam.id,
        members: Object.entries(teamRoles).map(([memberId, roles]) => {
          // filter out memberOnly and get only the active roles
          const activeRoles = Object.entries(roles)
            .filter(([key, value]) => key !== 'memberOnly' && value === true)
            .map(([key]) => rolesMapping[key][0]);

          return {
            userId: memberId,
            roleCodes: activeRoles.length > 0 ? activeRoles : null,
          };
        }),
      };

      await OrganizationService.updateTeamMembers(requestData);

      handleCloseTeamModal();

      if (onTeamsUpdate) {
        onTeamsUpdate();
      }
    } catch (error) {
      console.error('Error updating team members:', error);
    }
  };

  const handleProjectChange = (event, value) => {
    setSelectedProject(value);
    if (value) {
      updateMemberRoles(value.id);
    } else {
      // reset roles when no project is selected
      const emptyRoles = {};
      selectedRows.forEach((row) => {
        emptyRoles[row] = { memberOnly: true };
      });
      setTeamRoles(emptyRoles);
    }
  };

  const handleConfirmProjectChanges = async () => {
    if (!selectedProject) {
      // TODO: show error message that project is required
      return;
    }

    try {
      // format the data for the API
      const requestData = {
        projectId: selectedProject.id,
        members: Object.entries(teamRoles).map(([memberId, roles]) => {
          // filter out memberOnly and get only the active roles
          const activeRoles = Object.entries(roles)
            .filter(([key, value]) => key !== 'memberOnly' && value === true)
            .map(([key]) => rolesMapping[key][0]);

          return {
            userId: memberId,
            roleCodes: activeRoles.length > 0 ? activeRoles : null,
          };
        }),
      };

      await ProjectService.updateProjectMembers(requestData);

      handleCloseProjectModal();

      if (onTeamsUpdate) {
        onTeamsUpdate();
      }
    } catch (error) {
      console.error('Error updating project members:', error);
    }
  };

  const handleBulkDelete = async () => {
    try {
      await TeamsService.bulkDeleteTeams(selectedRows);
      setShowDeleteConfirm(false);
      setSelectedRole('');
      // trigger teams update
      if (onTeamsUpdate) {
        onTeamsUpdate();
      }
    } catch (error) {
      console.error('Error deleting teams:', error);
    }
  };

  const handleConfirmTransfer = async (selectedOrg) => {
    const data = { targetOrganizationId: selectedOrg, userIds: selectedRows };
    try {
      await OrganizationService.transferMembers(org.id, data);
      setShowTransferConfirm(false);
      // trigger members update
      if (onTeamsUpdate) {
        onTeamsUpdate();
      }
    } catch (error) {
      console.error('Error transferring members:', error);
    }
  };

  const handleCloseTransferModal = (func = () => {}) => {
    setSelectedRole('all');
    setShowTransferConfirm(false);
    func();
  };

  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              displayEmpty
              disabled={selectedRows.length === 0}
              sx={{
                height: '44px',
                width: '320px',
                backgroundColor: 'transparent',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '& .MuiSelect-select': {
                  color: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
              }}
            >
              <MenuItem value="all" disabled>
                Select Option
              </MenuItem>
              {roleOptions.map((option, index) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{
                    fontSize: '16px',
                    padding: '10px 14px',
                    borderTop: index === roleOptions.length - 1 ? '1px solid gray' : 'none',
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} sx={{ position: 'absolute', right: '16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'end' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size={20} />
                  </InputAdornment>
                ),
              }}
              value={filters?.search || ''}
              onChange={(e) => filterOptions(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  backgroundColor: 'transparent',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '& input': {
                    color: 'white',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleOpenFilters}
              sx={{
                height: '40px',
                minWidth: '100px',
                whiteSpace: 'nowrap',
                backgroundColor: filters?.activated ? 'primary.main' : 'transparent',
                '&:hover': {
                  backgroundColor: filters?.activated ? 'primary.dark' : 'rgba(255, 255, 255, 0.08)',
                },
              }}
            >
              <FilterIcon size={15} /> <div style={{ marginLeft: '6px' }}>Filters {filters?.activated && '(On)'}</div>
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Delete Modal */}
      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)} maxWidth="sm">
        <div style={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
          <AlertTriangleIcon size={28} stroke="#e57373" background="#fff9c4" />
        </div>
        <DialogTitle variant="h4">Remove Teams</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selectedRows.length} team{selectedRows.length !== 1 ? 's' : ''} from the
            organization?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ py: 1 }}>
          <Button onClick={() => setShowDeleteConfirm(false)} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleBulkDelete} color="error" variant="contained">
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Project Modal */}
      <MemberRolesModal
        open={showProjectModal}
        onClose={handleCloseProjectModal}
        title="Add or Remove User from Project"
        description="Add the selected users to a project and adjust their roles. Deselecting all boxes for a member will remove them from the project."
        entityType="project"
        entities={projects}
        selectedEntity={selectedProject}
        onEntityChange={handleProjectChange}
        members={[]}
        memberRoles={teamRoles}
        onMemberRoleChange={handleMemberRoleChange}
        onConfirm={handleConfirmProjectChanges}
        rolesConfig={[
          { key: 'memberOnly', label: 'Member Only', disabled: true },
          { key: 'participantCoordinator', label: 'Participant\nCoordinator' },
          { key: 'contentCoordinator', label: 'Content\nCoordinator' },
          { key: 'projectAdmin', label: 'Project\nAdministrator' },
        ]}
      />

      {/* Team Modal */}
      <MemberRolesModal
        open={showTeamModal}
        onClose={handleCloseTeamModal}
        title="Add or Remove User from Team"
        description="Add the selected users to a team and adjust their roles. Deselecting all boxes for a member will remove them from the team."
        entityType="team"
        entities={teams}
        selectedEntity={selectedTeam}
        onEntityChange={handleTeamChange}
        members={[]}
        memberRoles={teamRoles}
        onMemberRoleChange={handleMemberRoleChange}
        onConfirm={handleConfirmTeamChanges}
        rolesConfig={[
          { key: 'memberOnly', label: 'Member Only', disabled: true },
          { key: 'participantCoordinator', label: 'Participant\nCoordinator' },
          { key: 'contentCoordinator', label: 'Content\nCoordinator' },
          { key: 'projectAdmin', label: 'Project\nAdministrator' },
        ]}
      />

      {/* Transfer Modal */}
      <ModalTransferMembers
        isOpen={showTransferConfirm}
        onCancel={handleCloseTransferModal}
        onConfirm={handleConfirmTransfer}
        currentOrg={org}
        orgList={orgList}
      />
    </Box>
  );
};

ProjectsFilters.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  showProjectModal: PropTypes.bool.isRequired,
  setShowProjectModal: PropTypes.func.isRequired,
};

ProjectsFilters.defaultProps = {
  selectedRows: [],
};
