import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

const colors = {
  warning: { color: 800, background: 300 },
};

export const ModalHeaderIcon = ({ icon: Icon, text, color, background = '', size = 20 }) => {
  const classes = useStyles({ color: color ? colors[color] : null });

  return (
    <>
      {background ? (
        <div
          style={{
            width: size * 1.6,
            height: size * 1.6,
            borderRadius: '100%',
            background: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: size * 1.3,
              height: size * 1.3,
              borderRadius: '100%',
              background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon size={size} />
          </div>
        </div>
      ) : (
        <div className={classes.iconContainer}>
          <Icon size={size} />
        </div>
      )}
      {text && (
        <div style={{ paddingTop: '10px' }}>
          <Typography variant="h4" align="left">
            {text}
          </Typography>
        </div>
      )}
    </>
  );
};

ModalHeaderIcon.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.any,
  color: PropTypes.any,
  background: PropTypes.string,
  size: PropTypes.number,
};

ModalHeaderIcon.defaultProps = {
  icon: null,
  text: '',
  color: '',
  background: '',
  size: 20,
};
