import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { CloseIcon, CirclePlusIcon, LinkedFilesAddIcon } from 'components/Icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useProjectAssetContentContext } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContentContext';
import { setSearch } from 'slices/profileProjectAssetReducer';
import { setGisServices } from 'slices/mapGisReducer';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { PROJECT_TABS } from './lib';
import { LinkedFilesModal } from './LinkedFiles/LinkedFilesModal';

export const ProjectHeaderDetail = ({ data, tab, loading, setTabValue, onLinkedFileAdded }) => {
  const [isLinkedFilesModalOpen, setLinkedFilesModalOpen] = useState(false);
  const { setOpen, open, handleClose, config, setFullWidth, setActivePanel, setHeaderPanels } =
    useContext(SidebarDetailContext);
  const { unsavedChanges, setOpenUnsavedDialog, setContinueAction } = useProjectAssetContentContext();
  const component = config?.header?.find((item) => item.name === 'projectDetail');
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenTab = (index) => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => () => setTabValue(index));
    } else {
      setTabValue(index);
    }
  };

  const handleCloseAction = () => {
    if (component?.destination) {
      const { destination } = component;
      // validate the parent origin and redirect to the org dashboard
      if (destination?.name === 'dashboard') {
        // add the origin to the headers panels
        setHeaderPanels([
          {
            name: destination?.name,
            component: destination?.component,
            actions: {
              handleClose: () => {
                setActivePanel('');
              },
            },
          },
        ]);
        setFullWidth(true);
        setActivePanel(component?.destination.name);
      }
    } else {
      setOpen(!open);
      dispatch(setSearch(''));
      handleClose();
    }
  };

  const handleCloseProject = () => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
      setContinueAction(() => handleCloseAction);
    } else {
      handleCloseAction();
    }
  };

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Typography variant="h1">{data?.name}</Typography>

        <div className={classes.title}>
          <Button
            onClick={() => dispatch(setGisServices(true))}
            sx={{ display: PROJECT_TABS.PROJECT_MAP_GIS === tab ? null : 'none' }}
            className={classes.headerGisButton}
            startIcon={<CirclePlusIcon size={20} fill="#000000" />}
            color="secondary"
            variant="contained"
            disableElevation
          >
            Maps&#47;GIS Service
          </Button>
          {tab === PROJECT_TABS.PROJECT_LINKED_FILES && (
            <>
              <Button
                onClick={() => setLinkedFilesModalOpen(true)}
                className={classes.headerAddLinkedFileButton}
                startIcon={<LinkedFilesAddIcon size={20} />}
                color="secondary"
                variant="contained"
                disableElevation
                sx={{
                  margin: 0.5,
                  textTransform: 'none',
                  marginRight: '8px',
                  '& .MuiButton-startIcon': {
                    marginRight: '8px',
                  },
                }}
              >
                Add New
              </Button>
              <LinkedFilesModal
                open={isLinkedFilesModalOpen}
                onClose={() => setLinkedFilesModalOpen(false)}
                projectId={data?.id}
                onFileUploaded={() => {
                  setLinkedFilesModalOpen(false);
                  if (onLinkedFileAdded) {
                    onLinkedFileAdded();
                  }
                }}
              />
            </>
          )}
          <Button color="primary" variant="contained" onClick={handleCloseProject} className={classes.headerButton}>
            <CloseIcon size={15} />
          </Button>
        </div>
      </div>
      <Tabs value={tab} className={classes.headerTabs}>
        <Tab label="Overview" onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_INFO)} disabled={loading} />
        <Tab label="Content" onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_ASSETS)} disabled={loading} />
        <Tab label="Observations" onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_OBSERVATIONS)} disabled={loading} />
        <Tab
          label="Participants"
          onClick={() => handleOpenTab(PROJECT_TABS.PARTICIPANT_COORDINATION)}
          disabled={loading}
        />
        <Tab label="GIS/Maps" onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_MAP_GIS)} disabled={loading} />
        <Tab
          label="Active Inspections"
          onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_ACTIVE_INSPECTIONS)}
          disabled={loading}
        />
        <Tab label="Linked Files" onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_LINKED_FILES)} disabled={loading} />
        <Tab label="Delete Pending" onClick={() => handleOpenTab(PROJECT_TABS.COMMING_SOON_1)} disabled={loading} />
        <Tab label="Action Required" onClick={() => handleOpenTab(PROJECT_TABS.COMMING_SOON_2)} disabled={loading} />
        <Tab label="Settings" onClick={() => handleOpenTab(PROJECT_TABS.PROJECT_SETTINGS)} disabled={loading} />
      </Tabs>
    </div>
  );
};

ProjectHeaderDetail.propTypes = {
  data: PropTypes.object,
  tab: PropTypes.number,
  loading: PropTypes.bool,
  setTabValue: PropTypes.func,
  onLinkedFileAdded: PropTypes.func,
};

ProjectHeaderDetail.defaultProps = {
  data: { id: null, name: '', loading: false },
  tab: 0,
  loading: false,
  setTabValue: () => {},
  onLinkedFileAdded: () => {},
};
