import React, { useEffect, useRef } from 'react';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import Layer from '@arcgis/core/layers/Layer';
import { defaultZoom } from 'components/MapFeatureLayer/MapFeatureLayer';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const Map = ({ map }) => {
  const mapDiv = useRef(null);
  const classes = useStyles();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /* Initialize the map */
    if (mapDiv.current) {
      /* Creating the map */
      let webMap = new WebMap({
        basemap: 'hybrid',
      });
      let view;

      if (map.type === 'Web Map') {
        webMap = new WebMap({
          portalItem: {
            id: map.id,
          },
        });
      } else {
        Layer.fromPortalItem({
          portalItem: {
            id: map.id,
          },
        }).then((layer) => webMap.add(layer));
      }

      if (map.type === 'Scene Service') {
        view = new SceneView({
          container: mapDiv.current,
          map: webMap,
        });
      } else {
        view = new MapView({
          container: mapDiv.current,
          map: webMap,
        });
      }

      // load
      view.when().then(() => {
        const { layers } = view.map;
        if (layers.items.length) {
          const layer = layers.items[0];

          layer.when().then(() => {
            if (layer.isResolved()) {
              let longitude;
              let latitude;
              if (layer?.fullExtent) {
                longitude = layer?.fullExtent?.center?.longitude;
                latitude = layer?.fullExtent?.center?.latitude;
              }
              if (layer?.extent) {
                longitude = layer?.extent?.center?.longitude;
                latitude = layer?.extent?.center?.latitude;
              }
              if (longitude < 0 && latitude > 0) {
                defaultZoom(view, [longitude, latitude], 10);
              }
            }
          });
        }
      });

      return () => {
        if (view && webMap) {
          view.destroy();
          webMap.destroy();
        }
      };
    }
  }, [map]);

  return (
    <div className={classes.mapContainer}>
      <div ref={mapDiv} className={classes.map} id="mapContainer" />
    </div>
  );
};

Map.propTypes = {
  map: PropTypes.object,
};

Map.defaultProps = {
  map: {},
};
